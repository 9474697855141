import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { FooterBgImg, BoldSpan, CustomSpan } from './style'
import styled from 'styled-components'
import { Grid, FormGroup, Button } from '@mui/material'
import { screenSizes } from 'shared/styles/theme'
import history from 'modules/app/components/history'

const Footer = (props: any) => {
    const location = useLocation();

    const [ current_url, setCurrentUrl ] = useState('')

    useEffect(() => {
      setCurrentUrl(location.pathname)
    }, [location])
  
    
    return (
      <>
        {
          current_url === '/admin' ? <></>
          :
          current_url === '/admin/products' ? <></>
          :
          current_url === '/admin/signin' ? <></>
          :
          current_url === '/quiz/list/all' ? <></>
          :
          current_url.startsWith('/quiz/') === true ? <></>
          :
          current_url === '/admin/quiz' ? <></>
          :
          current_url === '/admin/quiz-analyze' ? <></>
          :
          current_url === '/admin/quiz-analyze-users' ? <></>
          :
          current_url === '/admin/data' ? <></>
          :
          <>
            <FooterContainer>
              <CustomGrid container spacing={0} sx={{maxWidth: '1320px'}}>
                  <CustomGrid container item xs={12} sm={12} md={12} >
                   <div className='footer_div_wrapper'>
                      <div className='footer_div_col'>
                        <h2 className="logoFooter_justify_content_center">
                          <a href="/">
                            <img src={require('assets/logo_avatar_05.png').default} style={{width: '52px!important'}} alt="" />
                            &nbsp;<span><BoldSpan>P</BoldSpan>roduct<BoldSpan>W</BoldSpan>iz</span>
                          </a>
                        </h2>

                        <div className="footer_center_justify_content_center">
                          <a href="mailto:">Contact</a>
                          <a href="/s_doc/privacy">Privacy Policy</a>
                          <a href="/s_doc/terms">Terms and Conditions</a>
                        </div>

                        <p className="copyright">
                          Amazon, Amazon Prime, the Amazon logo and Amazon Prime logo are
                          trademarks of Amazon.com, Inc. or its affiliates <br />Copyright
                          © 2024 by ProductWiz.co
                        </p>
                    
                      </div>
                   </div>
                  </CustomGrid>
                 
              </CustomGrid>
          </FooterContainer>
          </>
        }
      </>
        
    );
};



const FooterContainer = styled.div`
display: flex;
justify-content: center;

//   @media (max-width: ${screenSizes.mediaM}px) {
//     display: none;
//   }
`
const CustomGrid = styled(Grid)`
  padding: 8px 20px;
  margin: 0px!important;

  @media (max-width: ${screenSizes.mediaM}px) {
    padding: 0px 5px;
  }

  div.footer_div_wrapper {
    background-color: #FFFFFF;
    // border: 1px solid "#EDEDED";
    border-radius: 22px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.08);
    margin-bottom: 20px;
    padding: 40px 40px 20px 40px;
    width: 100%;
    box-sizing: border-box;

    div.footer_div_col {
      width: 100%;
      max-width: 100%;
      padding-right: calc(1.5rem * .5);
      padding-left: calc(1.5rem * .5);
      margin-top: 0;
      box-sizing: border-box;

      h2.logoFooter_justify_content_center {
        display: flex;
        margin-bottom: 20px;
        justify-content: center!important;
        font-size: 2rem;
        margin-top: 0;
        font-weight: 500;
        line-height: 1.2;
        box-sizing: border-box;
      }

      h2.logoFooter_justify_content_center a {
        display: inline-flex;
        text-decoration: none!important;
        -webkit-tap-highlight-color: transparent;
        // -webkit-touch-callout: none;
        outline: none;
        box-sizing: border-box;
        justify-content: center;
        align-items: center;
        font-size: 25px;
        color: #000;
        cursor: pointer;

        img {
          width: 45px;
          height: 45px;
        }
      }

      div.footer_center_justify_content_center {
        margin-bottom: 30px;
        display: flex;
        justify-content: center!important;
        box-sizing: border-box;

        @media (max-width: ${screenSizes.mediaM}px) {
          flex-direction: column;
          align-items: center;
        }
      }

      div.footer_center_justify_content_center a {
        display: flex;
        font-family: GT Pressura, sans-serif;
        padding: 0 10px;
        line-height: 17px;
        text-align: center;
        font-size: 14px;
        font-weight: 600;
        color: #000;
        transition: linear all 0.2s;
        text-decoration: none!important;
        -webkit-tap-highlight-color: transparent;
        box-sizing: border-box;
      }

      p.copyright {
        border-top: 1px solid #F1EFE9;
        padding-top: 20px;
        margin-bottom: 0px;
        color: #A6A6A6;
        font-size: 12px;
        font-family: GT Pressura, sans-serif;
        text-align: center;
        line-height: 18px;
        margin-top: 0;
        box-sizing: border-box;
      }

    }
  }
`

const mapStateToProps = (state: any) => ({
    
})


export default connect(mapStateToProps)(Footer);