import { useState, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import styled from 'styled-components'
import { Grid, ListItemButton, IconButton } from '@mui/material'
import { get_products_with_family_name, update_item_position, uploadImage, update_buyer_guide_image_url_by_id } from 'modules/api/market-api'

import DriveFileMoveOutlinedIcon from '@mui/icons-material/DriveFileMoveOutlined';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AddCategoryDialog from './AddCategoryDialog'
import EditCategoryDialog from './EditCategoryDialog'
import AddItemDialog from './AddItemDialog copy'
import EditItemDialog from './EditItemDialog'
import EditItemTitleDialog from './EditItemTitleDialog'
import DelItemDialog from './DelItemDialog'
import DelCategoryDialog from './DelCategoryDialog'
import MoveCategoryDialog from './MoveCategoryDialog';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import history from 'modules/app/components/history'
import { screenSizes } from 'shared/styles/theme'
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto'


const TabPanelDiv = (props: any) => {
    const { list_name } = props;
    const [ products, setProducts ] = useState<any>([])
    const [ content_items, setContentItems ] = useState<any>([])
    const [ addDialogOpen, setAddDialogOpen ] = useState<boolean>(false)
    const [ editDialogOpen, setEditDialogOpen ] = useState<boolean>(false)
    const [ editProductindex, setEditProductIndex ] = useState<number>(-1)
    const [ addItemDialogOpen, setAddItemDialogOpen ] = useState<boolean>(false)
    const [ editItemDialogOpen, setEditItemDialogOpen ] = useState<boolean>(false)
    const [ editItemTitleDialogOpen, setEditItemTitleDialogOpen ] = useState<boolean>(false)
    const [ delItemDialogOpen, setDelItemDialogOpen ] = useState<boolean>(false)
    const [ delCategoryDialogOpen, setDelCategoryDialogOpen ] = useState<boolean>(false)
    const [ moveCategoryDialogOpen, setMoveCategoryDialogOpen ] = useState<boolean>(false)
    const [ editItemIndex, setEditItemIndex ] = useState<number>(-1)
    const [ editItemTitleIndex, setEditItemTitleIndex ] = useState<number>(-1)
    const [ delItemIndex, setDelItemIndex ] = useState<number>(-1)
    const [ delCategoryIndex, setDelCategoryIndex ] = useState<number>(-1)
    const [ moveCategoryIndex, setMoveCategoryIndex ] = useState<number>(-1)
    const [ isLoading, setIsLoading ] = useState<boolean>(false)
    // const [ isFirstLoading, setIsFirstLoading ] = useState<boolean>(false)
    const [files1, setFiles1] = useState<any>([])
    const [avatar1, setAvatar1] = useState<string | undefined>('')
    const [imageSaveBtn_show1, setImageSaveBtnShow1] = useState<boolean>(false)

    useEffect(() => {
        try {
            (async () => {
                props.setIsFirstLoading(true)

                const result:any = await get_products_with_family_name(list_name)
                if( result.status === 'success' ) {
                    let products = result.data.products
                    products = products.map((product:any, index:number) => {
                        product = Object.assign(product, {active: false})
                        return product
                    })
                    setProducts(products)
                    
                    props.setIsFirstLoading(false)
                } else if ( result.status === 'error' ) {
                    props.setIsFirstLoading(false)
                    
                    history.push('/admin/signin')
                }
                
            })()
        } catch ( e ) {
            // console.log(e)
        }
    }, [list_name])

    useEffect(() => {
        try {
            (async () => {
                let active_product_index = products.findIndex((ele:any) => ele.active === true)
                if ( active_product_index !== -1 ) {
                    // console.log(products[active_product_index])
                    setContentItems(products[active_product_index].items)
                    setFiles1([{ preview: products[active_product_index].buyer_guide_image_url }])
                    setImageSaveBtnShow1(false)
                } else if ( active_product_index === -1 ) {
                    setContentItems([])
                }
            })()
        } catch ( e ) {
            // console.log(e)
        }
    }, [products])


    const clicked_category = (active: boolean, index: number, product_name: string, product_id: string) => {
        if ( active === true ) {

        } else if ( active === false ) {
            setProducts((prev: any[]) => {
                let next = [...prev]

                let prev_active_index = next.findIndex(ele => ele.active === true)

                if ( prev_active_index !== -1 ) {
                    next[prev_active_index].active = false
                }

                next[index].active = true
                
                return next
            })

            // setContentItems(products[index].items)
        }
    }

    const clicked_move_category = (e: any, index: number, product_name: string, product_id: string) => {
        e.stopPropagation()

        setMoveCategoryDialogOpen(true)
        setMoveCategoryIndex(index)
    }

    const clicked_edit_category = (e: any, index: number, product_name: string, product_id: string) => {
        e.stopPropagation()
        setEditDialogOpen(true)
        setEditProductIndex(index)
    }

    const clicked_delete_category = (e: any, index: number, product_name: string, product_id: string) => {
        e.stopPropagation()
        setDelCategoryIndex(index)
        setDelCategoryDialogOpen(true)
            
    }

    const clicked_row = (e:any, index:number, item:any) => {
        e.stopPropagation()

        setEditItemTitleDialogOpen(true)
        setEditItemTitleIndex(index)
    }

    const clicked_edit_item = (e:any, index:number, item: any) => {
        e.stopPropagation()

        setEditItemDialogOpen(true)
        setEditItemIndex(index)
    }

    const clicked_delete_item = (e:any, index:number, item: any) => {
        e.stopPropagation()
        setDelItemIndex(index)
        setDelItemDialogOpen(true)
    }

    const clicked_pos_up = (e:any, index:number, item: any) => {
        (async () => {
            e.stopPropagation()

            if ( content_items.length === 1 ) {
    
            } else if ( content_items.length > 1 ) {
                setIsLoading(true)

                let active_product_index = products.findIndex((ele:any) => ele.active === true)
                let product_id = products[active_product_index]._id
                let update_result = await update_item_position(product_id, content_items, index, 'up')

                if ( update_result.status === "success" ) {
                    setIsLoading(false)
                    await new Promise(r => setTimeout(r, 300))
                    // setContentItems(update_result.update_result.items)
                    
                    setProducts((prev:any[]) => {
                        let next = [...prev]
                        next[active_product_index].items = update_result.update_result.items

                        return next
                    })
                } else if ( update_result.status === "error" ) {
                    setIsLoading(false)
                }
                // if ( index === 0 ) {
                //     setContentItems((prev: any[]) => {
                //         let next = [...prev]
                //         let temp_item = next[index]
    
                //         next.shift()
                //         next.push(temp_item)
    
                //         return next
                //     })
                // } else {
                //     setContentItems((prev: any[]) => {
                //         let next = [...prev]
                //         let temp_item = next[index]
    
                //         next.splice(index, 1)
                //         next.splice(index - 1, 0, temp_item)
    
                //         return next
                //     })
                // }
            }
        })()
    }

    const clicked_pos_down = (e:any, index:number, item: any) => {
        (async () => {
            e.stopPropagation()
       
            if ( content_items.length === 1 ) {
    
            } else if ( content_items.length > 1 ) {
                setIsLoading(true)

                let active_product_index = products.findIndex((ele:any) => ele.active === true)
                let product_id = products[active_product_index]._id
                let update_result = await update_item_position(product_id, content_items, index, 'down')

                if ( update_result.status === "success" ) {
                    setIsLoading(false)
                    await new Promise(r => setTimeout(r, 300))
                    // setContentItems(update_result.update_result.items)
                    
                    setProducts((prev:any[]) => {
                        let next = [...prev]
                        next[active_product_index].items = update_result.update_result.items

                        return next
                    })
                } else if ( update_result.status === "error" ) {
                    setIsLoading(false)
                }

            }
        })()
    }

    const add_category = () => {
        setAddDialogOpen(true)
    }

    const add_item = () => {
        setAddItemDialogOpen(true)
    }

    const { getRootProps: getRootFileProps1, getInputProps: getInputFileProps1 } = useDropzone({
        accept: {
          'image/*': [],
        },
        onDrop: (acceptedFiles) => {
          setFiles1(
            acceptedFiles.map((file) => {
              return Object.assign(file, {
                preview: URL.createObjectURL(file),
              })
            })
          )
    
          setImageSaveBtnShow1(true)
        },
        multiple: false,
      })

    const thumbs1 = files1.map((file: any, index: number) => (
        <div className="imageuploadinner_background" key={index}>
          {file.preview === '' || file.preview === undefined ? (
            <></>
          ) : (
            <img
              src={file.preview}
              onLoad={() => {
                URL.revokeObjectURL(file.preview)
              }}
            />
          )}
        </div>
      ))

      const save_edited_image1 = (e: any) => {
        try {
          ; (async () => {
                let active_product_index = products.findIndex((ele: any) => ele.active === true)
        
                e.stopPropagation()
                setIsLoading(true)
        
                let formData: FormData = new FormData()
                formData.append('file', files1[0])
                let img_upload_result = await uploadImage(formData)
        
                // setIsLoading(false)
        
                if (img_upload_result.status === 'success') {
                    let image_url = img_upload_result.data.Location
            
                    let update_result: any = await update_buyer_guide_image_url_by_id(
                        products[active_product_index]._id,
                        image_url
                    )
            
                    if (update_result.status === 'success') {
                        setProducts((prev: any[]) => {
                        let next = [...prev]
            
                        next[active_product_index].buyer_guide_image_url = image_url
            
                        return next
                        })
            
                        setIsLoading(false)
                    } else if (update_result.status === 'error') {
                        setIsLoading(false)
                    }
                } else if (img_upload_result.status === 'error') {
                setIsLoading(false)
                }
          })()
        } catch (e) {
          setIsLoading(false)
          return
        }
      }

    return (
        
        <>
            {
                isLoading === true ?
                 <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={isLoading}
                    // onClick={handleClose}
                >
                    <CircularProgress color="inherit"/>
                    <p>&nbsp;&nbsp; Saving Data ...</p>
                </Backdrop> :<></>
            }
            <CustomGrid container spacing={0}>
                <CustomGrid item xs={3} >
                    <CustomDiv className={"add_category_div"}>
                        <CustomListItemButton className={"min-width-0 add_category_icon"} onClick={(e)=> add_category()}>
                            <PlaylistAddIcon sx={{'width': '32px', 'height': '32px'}}/>
                        </CustomListItemButton>
                    </CustomDiv>
                    <CustomDiv className={"list_div"}>
                        
                        {
                            products.length > 0 && products.map((product:any, index:number) => (
                                <CustomDiv key={index} className={"btn_div"}>
                                    <CustomDiv className={product.active === true ? "active_list_item btn_style" : "btn_style"} onClick={() => clicked_category(product.active, index, product.product_name, product._id)}>
                                        <CustomDiv className={"txt_div"}>
                                            {product.product_name}
                                        </CustomDiv>                                        
                                        <CustomDiv className={"min-width-0 edit_icon"} onClick={(e:any)=> clicked_edit_category(e, index, product.product_name, product._id)}>
                                            <ModeEditOutlineIcon fontSize="small"/>
                                        </CustomDiv>
                                        <CustomDiv className={"min-width-0 move_icon"} onClick={(e:any)=> clicked_move_category(e, index, product.product_name, product._id)}>
                                            <DriveFileMoveOutlinedIcon fontSize="small"/>
                                        </CustomDiv>
                                        <CustomDiv className={"min-width-0 delete_icon"} onClick={(e:any) => clicked_delete_category(e, index, product.product_name, product._id)}>
                                            <DeleteOutlineIcon fontSize="small" />
                                        </CustomDiv>
                                        
                                    </CustomDiv>
                                </CustomDiv>
                            ))
                        }
                    </CustomDiv>
                </CustomGrid>
                <CustomGrid item xs={9} >
                    <CustomDiv className={"add_item_div"}>
                        <CustomListItemButton className={"min-width-0 add_item_icon"} onClick={(e)=> add_item()}>
                            {
                                products.findIndex((ele:any) => ele.active === true) === -1 ? <></>
                                 : products.findIndex((ele:any) => ele.active === true) !== -1 ? <PlaylistAddIcon sx={{'width': '32px', 'height': '32px'}}/> 
                                 : <></>
                            }
                            
                        </CustomListItemButton>
                    </CustomDiv>
                    <CustomDiv className={"content_div"}>
                        
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                                <TableBody>
                                {
                                    content_items.length > 0 && content_items.map((item:any, index:any) => (
                                        <CustomTableRow
                                            hover
                                            key={index}
                                            className={"row_style"}
                                            onClick={(e:any) => {clicked_row(e, index, item)}}
                                        >
                                            <CustomTableCell component="th" scope="row">
                                                {/* {item.DetailPageURL.slice(0, item.DetailPageURL.indexOf('?') + 6) + "..."} */}
                                                {item.DetailPageURL.length > 0 && item.DetailPageURL.slice(0, 70) + "..."}
                                            </CustomTableCell>
                                            <CustomTableCell align="right"><CustomListItemButton onClick={(e:any) => {clicked_edit_item(e, index, item)}} className={"edit_cell"}>{"edit"}</CustomListItemButton></CustomTableCell>
                                            <CustomTableCell align="right"><CustomListItemButton onClick={(e:any) => {clicked_delete_item(e, index, item)}} className={"delete_cell"}>{"delete"}</CustomListItemButton></CustomTableCell>
                                            <CustomTableCell align="right"><CustomListItemButton onClick={(e:any) => {clicked_pos_up(e, index, item)}} className={"arrow"}><KeyboardDoubleArrowUpIcon /></CustomListItemButton></CustomTableCell>
                                            <CustomTableCell align="right"><CustomListItemButton onClick={(e:any) => {clicked_pos_down(e, index, item)}} className={"arrow"}><KeyboardDoubleArrowDownIcon /></CustomListItemButton></CustomTableCell>
                                        </CustomTableRow>
                                    ))
                                }
                                </TableBody>
                            </Table>
                        </TableContainer>

                        {
                            products.findIndex((ele:any) => ele.active === true) === -1 ? <></>
                                : products.findIndex((ele:any) => ele.active === true) !== -1 ? 
                                    <div className={'quiz_content_item_div'}>
                                        <CustomGrid container spacing={0}>
                                            <CustomGrid item xs={10} className={'mui_grid_flex'}>
                                            <div className={'quiz_content_item_title'}>Buyer's Guide Image: </div>
                                            </CustomGrid>
                                        </CustomGrid>
                                        <CustomDiv1 className={'img_div_background'}>
                                            <Page>
                                                <section className="imginner_background">
                                                    <aside className="imageupload_background">
                                                    {files1.length > 0 ? (
                                                        thumbs1
                                                    ) : avatar1 === '' || avatar1 === undefined ? (
                                                        <div className="imageuploadinner_background">
                                                        </div>
                                                    ) : (
                                                        <div className="imageuploadinner_background">
                                                        <img src={avatar1} alt="Avatar" />
                                                        </div>
                                                    )}
                                                    <div {...getRootFileProps1({ className: 'dropzone' })}>
                                                        <input {...getInputFileProps1()} />
                                                        <IconButton aria-label="more" id="long-button" aria-haspopup="true" className="icon_btn_right_background">
                                                            <InsertPhotoIcon />
                                                        </IconButton>
                                                    </div>
                                                    </aside>
                                                    <div className="dropzoneOuter_background">
                                                        {imageSaveBtn_show1 === true ? (
                                                            <CustomListItemButton
                                                                aria-label="more"
                                                                id="long-button"
                                                                aria-haspopup="true"
                                                                className="image_save_btn"
                                                                onClick={(e) => save_edited_image1(e)}
                                                            >
                                                                save
                                                            </CustomListItemButton>
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </div>
                                                </section>
                                            </Page>
                                        </CustomDiv1>
                                    </div>
                                : <></>
                        }

                        
                    </CustomDiv>
                </CustomGrid>
            </CustomGrid>
            <AddCategoryDialog open={addDialogOpen} setOpen={setAddDialogOpen} family_name={list_name} products={products} setProducts={setProducts} />
            <EditCategoryDialog open={editDialogOpen} setOpen={setEditDialogOpen} edit_product_index={editProductindex} setEditProductIndex={setEditProductIndex} products={products} setProducts={setProducts} />
            <AddItemDialog open={addItemDialogOpen} setOpen={setAddItemDialogOpen} products={products} setProducts={setProducts} />
            <EditItemDialog open={editItemDialogOpen} setOpen={setEditItemDialogOpen} edit_item_index={editItemIndex} setEditItemIndex={setEditItemIndex} products={products} setProducts={setProducts} />
            
            <EditItemTitleDialog open={editItemTitleDialogOpen} setOpen={setEditItemTitleDialogOpen} edit_item_title_index={editItemTitleIndex} setEditItemTitleIndex={setEditItemTitleIndex} products={products} setProducts={setProducts} />
            
            <DelItemDialog open={delItemDialogOpen} setOpen={setDelItemDialogOpen} index={delItemIndex} setDelItemIndex={setDelItemIndex} products={products} setProducts={setProducts} />
            <DelCategoryDialog open={delCategoryDialogOpen} setOpen={setDelCategoryDialogOpen} index={delCategoryIndex} setDelCategoryIndex={setDelCategoryIndex} products={products} setProducts={setProducts} />
            <MoveCategoryDialog open={moveCategoryDialogOpen} setOpen={setMoveCategoryDialogOpen} move_list_array={props.move_list_array} index={moveCategoryIndex} setMoveCategoryIndex={setMoveCategoryIndex} products={products} setProducts={setProducts} />
        </>
    )
}
const Page = styled.div`
  color: #fff;
  display: flex;
  justify-content: Center;
  align-items: Center;

  .imginner {
    border: 1px solid rgba(0, 0, 0, 0.05) !important;
    border-radius: 24px !important;
  }
  .imginner_background {
    border: 1px solid rgba(0, 0, 0, 0.05) !important;
    // border-radius: 24px !important;
  }
  .imageupload {
    display: flex;
    justify-content: Center;
    border-radius: 10px;
    position: relative;
    .imageuploadinner {
      position: relative;
      @media (min-width: ${screenSizes.mediaM}px) {
        width: 300px;
        height: 300px;
      }
      width: 250px;
      height: 250px;
      overflow: hidden;
      img {
        height: 100%;
        width: 100%;
        border-radius: 24px !important;
        object-fit: contain;
      }
      .icon_btn {
        color: rgb(120, 115, 115);
        position: absolute;
        top: 30%;
        left: 30%;
        &:hover {
          background-color: white !important;
          color: black;
        }
      }
    }
    .icon_btn_right {
      color: rgb(120, 115, 115);
      border: 1px solid transparent !important;
      position: absolute;
      top: 2%;
      right: 2%;
      transition: all 300ms ease-in-out;
      &:hover {
        background-color: #ffffff7a !important;
        color: #049cf7;
        border: 1px solid #e3ecf35e !important;
        box-shadow: 0 1px 15px rgb(178 217 247 / 52%), 0 1px 15px rgb(117 169 235 / 23%) !important;
      }
    }
  }
  .imageupload_background {
    display: flex;
    justify-content: Center;
    border-radius: 10px;
    position: relative;
    .imageuploadinner_background {
      position: relative;
      @media (min-width: ${screenSizes.mediaM}px) {
        width: 300px;
        height: 300px;
      }
      width: 250px;
      height: 250px;
      overflow: hidden;
      img {
        height: 100%;
        width: 100%;
        // border-radius: 24px !important;
        object-fit: contain;
      }
      .icon_btn {
        color: rgb(120, 115, 115);
        position: absolute;
        top: 30%;
        left: 30%;
        &:hover {
          background-color: white !important;
          color: black;
        }
      }
    }
    .icon_btn_right_background {
      color: rgb(120, 115, 115);
      border: 1px solid transparent !important;
      position: absolute;
      top: 2%;
      right: 2%;
      transition: all 300ms ease-in-out;
      &:hover {
        background-color: #ffffff7a !important;
        color: #049cf7;
        border: 1px solid #e3ecf35e !important;
        box-shadow: 0 1px 15px rgb(178 217 247 / 52%), 0 1px 15px rgb(117 169 235 / 23%) !important;
      }
    }
  }

  .dropzoneOuter {
    color: rgba(0, 0, 0, 0.4);
    text-align: center;
    max-width: 200px;
    margin: auto;
  }
  .dropzoneOuter_background {
    color: rgba(0, 0, 0, 0.4);
    text-align: center;
    max-width: 200px;
    margin: auto;
  }
`

const CustomDiv1 = styled.div`
  &.img_text_container {
    display: flex !important;
    margin-bottom: 20px;
    .img_div {
    }
    .text_div {
      width: 100%;
      //   margin-left: 20px;
      display: flex;
      flex-direction: column;
      justify-content: start;
    }
  }
  &.num_quiz_div {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    input {
      margin-left: 10px;
      padding: 4px;
      font-size: 18px;
      line-height: 30px;
      border: 1px solid #c4c4c4 !important;
      border-radius: 5px !important;
      width: 50px !important;
    }
    input:hover {
      border-color: black !important;
    }
    input:focus-visible {
      border: 2px solid #1976d2 !important;
      outline: none !important;
    }
  }
`

const CustomGrid = styled(Grid)`
    &.mui_grid_flex_name {
        display: flex !important;
        flex-direction: row;
    }

    div.quiz_content_item_div {
        border: 1px solid #c0c2c3a3;
        border-radius: 10px;
        padding: 10px;
        box-shadow: 0 2px 6px #162a410f, 0 12px 24px #07152514;
        transition: all 150ms ease-in-out;
        margin: 5px 0!important;
        padding-bottom: 25px;
    }

    div.quiz_content_item_div:hover {
        /* border-color: #075dfc5e!important; */
        box-shadow: 0 2px 6px rgb(190 195 199 / 83%), 0 12px 24px rgb(66 70 75 / 82%);
    }

    div.quiz_content_item_title {
        /* font-size: 16px; */
        font-weight: 600;
    }
`

const CustomTableRow = styled(TableRow)`
    &.row_style {
        cursor: pointer!important;
    }
`

const CustomTableCell = styled(TableCell)`
    
`

const CustomListItemButton = styled(ListItemButton)`
    &.image_save_btn {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &.active_list_item {
        background-color: #1e947e;
        color: white;

    }
    &.edit_cell {
        display: flex;
        justify-content: center;
    }
    &.delete_cell {
        display: flex;
        justify-content: center;
    }
    &.edit_cell:hover {
        color: #009fff;
        background-color: #0db9f51f;
    }
    &.delete_cell:hover {
        color: #ff6000;
        background-color: #ff60002e;
    }

    &.add_category_icon {
        display: flex;
        justify-content: center;
        color: #1e947e;
    }

    &.add_item_icon{
        display: flex;
        justify-content: center;
        color: #1e947e;
    }
    &.add_category_icon: hover {
        color: #1e947e;
    }

    &.add_item_icon: hover {
        color: #1e947e;
    }

    &.arrow {
        display: flex;
        justify-content: center;
    }
`

const CustomDiv = styled.div`
    &.add_category_div {
        display: flex;
        justify-content: center;
        padding: 0px 0px 5px 0px;
    }

    &.add_item_div {
        display: flex;
        justify-content: center;
        padding: 0px 0px 5px 0px;
    }

    &.txt_div {
        flex: 1 1 auto!important;
    }

    &.list_div {
        height: 75vh;
        overflow: auto;
        border-right: 1px solid darkgray!important;
        padding-right: 5px!important;
        padding-top: 5px!important;
    }

    &.content_div {
        height: 75vh;
        overflow: auto;
        padding-left: 5px!important;
        padding-right: 5px!important;
        padding-top: 5px!important;
    }

    &.btn_style {
        display: flex;
        padding: 8px 16px;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
    }

    &.btn_div {
       margin: 2px 0px;
    }
    &.btn_div:hover {
       background-color: #00000022!important;
    }

    &.active_list_item {
        background-color: #1e947e;
        color: white;

    }

    &.min-width-0 {
        min-width: 0px!important;
        margin: 0 1px!important;
    }

    &.move_icon: hover {
        color: #fded0a!important
    }

    &.edit_icon: hover {
        color: #00ceff!important;
    }

    &.delete_icon: hover {
        color: #ff3100cf!important;
    }
`

export default TabPanelDiv