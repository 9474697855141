import { useState } from 'react'
import styled from 'styled-components'
import { Grid, Divider } from '@mui/material'
import { screenSizes } from 'shared/styles/theme'
import HomeIcon from '@mui/icons-material/Home';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ProductBoxMobile from './components/ProductBoxMobile';
import history from 'modules/app/components/history'
import TrustBoxMobile from './components/TrustBoxMobile'
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import OverviewBoxMobile from './components/OverviewBoxMobile'
import BuyersGuideBoxMobile from './components/BuyersGuideBoxMobile'

const MobileDiv = (props: any) => {
    const { items, title, product, overview, buyer_guide, buyer_guide_title, buyer_guide_image_url } = props

    const goStartPage = () => {
        history.push(`/`)
    }

    return (
        <CustomWrapperItems>
          <CustomGrid container spacing={0} >
            <CustomGrid item container spacing={0} md={12} className="grid-header-left grid-flex-item-start">
              <CustomGrid item container spacing={0} className="margin-padding-0_v-center">
                <CustomGrid item sm={12} className="margin-padding-0_v-center">
                  <ShortSpan onClick={goStartPage}>
                      <HomeIcon  />
                  </ShortSpan>
                  <span style={{color: 'gray', fontSize: '14px' }}>&nbsp;&nbsp;✦&nbsp;&nbsp;</span>
                  <ShortSpan>
                      {title}
                  </ShortSpan>
                  <span style={{color: 'gray', fontSize: '14px' }}>&nbsp;&nbsp;✦&nbsp;&nbsp;</span>
                  <ShortSpan className="lastSpan">
                      {product}
                  </ShortSpan>
                </CustomGrid>
              </CustomGrid>
                <CustomGrid item container spacing={0} className="margin-padding-0_v-center">
                    <CustomGrid item  sm={12} className="margin-padding-0_v-center">
                        <CategoryTitle>Best {product}</CategoryTitle>
                    </CustomGrid>
                </CustomGrid>
                <CustomGrid item container spacing={0} className="margin-padding-0_v-center">
                    <CustomGrid item sm={12} className="margin-padding-0_v-center">
                        <UpdatedSpan>(Updated December 2024)</UpdatedSpan>
                    </CustomGrid>
                </CustomGrid>
              </CustomGrid>
              
            </CustomGrid> 
            <Divider sx={{ boxShadow: '0px 1px 2px #0a0909'}} />
            <Box sx={{ width: '100%', typography: 'body1' }}>
              <CustomGrid container spacing={0} sx={{background: 'transparent!important', marginTop: '5px!important', padding: '0!important'}}>
                <CustomGrid item container spacing={0} sm={12} className="grid-header-left grid-header-right grid-flex-item-start" sx={{background: 'transparent!important'}} >
                    {   items.length !== 0 ?
                        items.length > 0 && items.map((item: any, index: number)=> {
                            return <ProductBoxMobile key={index} item={item} num={index + 1}/>
                        }) : ''
                    }
                </CustomGrid>
              </CustomGrid>
            </Box>
            <Box sx={{ width: '100%', typography: 'body1' }}>
              <CustomGrid container spacing={0} sx={{background: 'transparent!important', marginTop: '20px!important', justifyContent: 'center', maxWidth: '1320px'}}>
                <CustomGrid item container spacing={0} className=""  style={{padding: '0'}}>
                    <CustomGrid item  sm={12} className="margin-padding-0_v-center" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                        <CategoryTitle>{product} Buyer's Guide</CategoryTitle>
                        <span className='buyer_guide_title_sub'>{ buyer_guide_title }</span>
                    </CustomGrid>
                </CustomGrid>
                <CustomGrid item container spacing={0} className="" style={{padding: '0'}}>
                    <CustomGrid item sm={12} className="img_wrapper_grid">
                        <div className="img_div_wrapper">
                            <span className="img">
                              {
                                  buyer_guide_image_url === undefined || buyer_guide_image_url === null || buyer_guide_image_url?.trim() === '' ?
                                  items.length > 0 ? 
                                  <img src={items[0].Images?.Primary?.Large?.URL} alt="" title="" />
                                  :  
                                  <></>
                                  // <img src="https://www.buyersreport.org/cdn-cgi/image/width=900,f=auto,quality=85,onerror=redirect/https://d1ccm0vckus47s.cloudfront.net//home/ubuntu/www/releases/20230913173000/tmp/uploads/1694634426-101040460748555-0001-6035/image.png" alt="" title="" />
                                  :
                                  <img src={buyer_guide_image_url} alt="" title="" />
                              }
                                {/* <img src="https://www.buyersreport.org/cdn-cgi/image/width=900,f=auto,quality=85,onerror=redirect/https://d1ccm0vckus47s.cloudfront.net//home/ubuntu/www/releases/20230913173000/tmp/uploads/1694634426-101040460748555-0001-6035/image.png" alt="" title="" /> */}
                            </span>
                        </div>
                    </CustomGrid>
                </CustomGrid>

                <CustomGrid item md={12} className="grid-header-right grid-flex-item-start">
                  <CustomBox className="parent_box">
                    <CustomBox className="center_box">
                      <Box className="main_box">
                        <span className='overview_text'>
                            {overview}
                        </span>
                      </Box>
                    </CustomBox>
                  </CustomBox>
                </CustomGrid>

                <CustomGrid item container spacing={0} className="">
                    <CustomGrid item sm={12} className="margin-padding-0_v-center">
                        <UpdatedSpan className='buyer_guide_span'>{buyer_guide}</UpdatedSpan>
                    </CustomGrid>
                </CustomGrid>
              </CustomGrid>
            </Box>
            

        </CustomWrapperItems> 
    );
};

const CustomBox = styled(Box)`
  &.tab_box {
    span.css-1aquho2-MuiTabs-indicator {
      background-color: #1e947e!important;
    }
    span.css-ttwr4n {
      background-color: #1e947e!important;
    }
  }

  &.parent_box {
    width: 100%;
    display: flex;
    background: aliceblue;
    margin: 10px 0;
    border-radius: 10px;
    position: relative;
    .center_box {
        background: white;
        margin: 0;
        width: auto;
        flex-grow: 1;
        display: flex!important;
        align-items: center!important;
        justify-content: start!important;
        // padding: 20px 15px;
        img {
            width: 133px;
            height: 24px;
            margin-top: 5px;
          }
        .main_box {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .overview_text {
                color: #636060;
                font-family: "Roboto","Helvetica","Arial",sans-serif;
                font-weight: 400;
                font-size: 1rem;
                line-height: 1.5;
                letter-spacing: 0.00938em;
            }
        }
    }
  }
`

const CustomChevronRightIcon = styled(ChevronRightIcon)`
    margin: 0 5px;
`

const ImageAndTextM = styled.div`
  display: flex;
  align-items: center;
  // margin-bottom: 5px;
  img {
    // width: 25px!important;
    // height: 25px!important;
    margin-right: 5px;
  }
`

const CardSubTextM = styled.p`
    font-size: 15px;
    line-height: 15px;
    font-weight: 600;
    color: #187664;
    // color: #06bbf1;
    // font-family: 'Montserrat',sans-serif;
    font-family: GT Pressura, sans-serif;
    margin: 0;
`

const CustomWrapperItems = styled.div`
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: start;
    @media (min-width: ${screenSizes.mediaM}px) {
        display: none;
    }
`

const CustomGrid = styled(Grid)`
  background-color: white!important;

  padding: 2px 10px;
  margin: 0px!important;

  img {
    // width: 192px;
    // height: 48px;
  }

  .grid-flex-item-end {
    display: flex;
    justify-content: end;
  }

  .grid-flex-item-start {
    display: flex;
    justify-content: start;
  }

  .grid-header-left {
    @media (min-width: ${screenSizes.mediaXLL}px) {
      padding-left: 105px!important;
    }
    @media (min-width: ${screenSizes.mediaXXL}px) {
      padding-left: 155px!important;
    }
    @media (min-width: ${screenSizes.mediaXXXX}px) {
      padding-left: 185px!important;
    }
  }

  .grid-header-right {
    align-items: center;
    @media (min-width: ${screenSizes.mediaXLL}px) {
      padding-right: 105px;
    }
    @media (min-width: ${screenSizes.mediaXXL}px) {
      padding-right: 155px;
    }
    @media (min-width: ${screenSizes.mediaXXXX}px) {
      padding-right: 185px;
    }
  }

  .custom-paper {
    @media (min-width: ${screenSizes.mediaM}px) {
      width: 300px;
    }
    @media (min-width: ${screenSizes.mediaXL}px) {
      width: 355px;
    }
  }
  .margin-padding-0_v-center {
    display: flex;
    align-items: center;
    margin: 2px 0!important;
    padding: 0!important;
  }
 
  .imageTxt {
      margin: 0 10px 0 5px;
      color: gray!important;
  }

  span.buyer_guide_title_sub {
    letter-spacing: 0.01em;
    color: #747574;
    font-family: Inter,Arial,sans-serif;
    line-height: 1.5rem;
    font-weight: 600;
    font-size: 1rem;
    margin-bottom: 16px;
  }

  .img_wrapper_grid {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0;
  }
  .img_div_wrapper {
      width: 100%;
      max-width: 100%;
      display: flex;
      justify-content: center;

      span.img {
        display: flex;
        justify-content: center;
        border-radius: 20px;
        overflow: hidden;
        margin-bottom: 20px;
        text-align: center;

        img {
          object-fit: contain;
          width: 100%;
        }
      }
  }
`
const ShortSpan = styled.span`
    cursor: pointer!important;
    font-family: sans-serif;
    color: gray;
    &:hover {
        color: black;
    }
    &.lastSpan {
      font-size: 15px!important;
        // color: #187664!important;
        // color: #37a4f1!important;
    }
`
const CategoryTitle = styled.span`
    font-family: GT Pressura,sans-serif;
    color: black!important;
    font-weight: 600;
    font-size: 26px;
`
const UpdatedSpan = styled.span`
    color: #5e5c62;
    font-size: 24px;
    line-height: 22px;
    margin-top: 0;
    margin-bottom: 1rem;
    box-sizing: border-box;

    &.buyer_guide_span {
    font-size: 16px;
    }
`
const CustomTab = styled(Tab)`
    &.lowcasetab {
      text-transform: none!important;
      width: 50%!important;
        
    } 
    &.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
      color: #1e947e!important;
    }
    &.css-1q2h7u5.Mui-selected {
      color: #1e947e!important;
    }
`
export default MobileDiv