import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { Button } from '@mui/material'
import { recordUserAndIds } from 'modules/api/market-api'
import StarIcon from '@mui/icons-material/Star';
import StarHalfIcon from '@mui/icons-material/StarHalf';

interface Props {
    item: any,
    num: number,
}

const ProductBoxMobile = (props: Props) => {
    const { item, num } = props;
    const selector = useSelector((state: any) => state)

    const recordIds = (e:any) => {
        e.stopPropagation()

        let gclid = localStorage.getItem('gclid')
        let msclkid = localStorage.getItem('msclkid')
        let search = localStorage.getItem('search')
        let amazon_tracking_id = localStorage.getItem('amazon_tracking_id')
        let _id = localStorage.getItem('_id')
        recordUserAndIds(gclid, msclkid, amazon_tracking_id, item.DetailPageURL, search, _id, undefined)
            .then(result => {
            })
            .catch(err => {
                // console.log(err)
            })
    }

    return (
        <CustomA className="parent_wrapper" href={item.DetailPageURL} target="_blank" onClick={(e:any) => recordIds(e)}>
            <CustomDiv className="parent_box">
                <CustomDiv className="top_box">
                    <img src={item.Images.Primary.Large.URL ? item.Images.Primary.Large.URL : require('assets/1.jpg').default} alt="" width="90" height="90"/>
                </CustomDiv>
                <CustomDiv className="middle_box">
                    <div className="main_box">
                        <div className="part_box1">
                            <div className="part_box1_right">
                                {item.ItemInfo.ByLineInfo != null && item.ItemInfo.ByLineInfo.Brand != null ? item.ItemInfo.ByLineInfo.Brand.DisplayValue : ''}
                            </div>
                            {
                                num === 1 ? 
                                <div className="rating">
                                    <span className="number">10.0</span>
                                    <span style={{ margin: '0 10px' }}><CustomStarIcon /><CustomStarIcon /><CustomStarIcon /><CustomStarIcon /><CustomStarIcon /></span>
                                    <span className="total">({item.reviews_count_r} reviews)</span>
                                </div> : 
                                <div className="rating">
                                    <span className="number">9.8</span>
                                    <span style={{ margin: '0 10px' }}><CustomStarIcon /><CustomStarIcon /><CustomStarIcon /><CustomStarIcon /><CustomStarHalfIcon /></span>
                                    <span className="total">({item.reviews_count_r} reviews)</span>
                                </div>
                            }
                        </div>
                        <CustomA style={{margin: '15px 0'}} href={item.DetailPageURL} target="_blank" onClick={(e:any) => recordIds(e)}>
                            <div className="part_box2">
                                {item.ItemInfo.Title.DisplayValue}
                            </div>
                        </CustomA>
                    </div>
                </CustomDiv>
                <CustomDiv className="bottom_box">
                    <CustomA href={item.DetailPageURL} target="_blank" onClick={(e:any) => recordIds(e)}>
                        <CustomButton sx={{ textTransform: "none" }}>
                            Check Price
                        </CustomButton>
                    </CustomA>
                    <img src={require('assets/amazonButtonUnderImage.svg').default} alt="" width="100" height="20" />
                    <p className="picked">Picked by {item.picked_users_count} people today</p>
                </CustomDiv>

                {
                    num === 1 ? 
                    <div className="ribbon">Best Overall</div>
                    : <></>
                }
            </CustomDiv>
        </CustomA>
    )
}

const CustomStarIcon = styled(StarIcon)`
    color: #f8b200;
`

const CustomStarHalfIcon = styled(StarHalfIcon)`
    color: #f8b200;
`

const CustomA = styled.a`
    text-decoration: none;
    width: 100%;
    &.parent_wrapper {
        background-color: #ffffff;
        border: 2px solid #EDEDED;
        border-radius: 22px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.08);
        position: relative;
        transition: all linear 0.2s;
        margin-bottom: 20px;

        &:hover {
            border: 2px solid #ff8d00!important;
        }  
    }
`
const CustomButton = styled(Button)`
`
const CustomDiv = styled.div`
    &.parent_box {
        div.ribbon {
            background-color: #5b31dd;
            color: #ffffff;
            font-size: 12px;
            font-family: GT Pressura,sans-serif;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            padding: 0 10px;
            line-height: 30px;
            position: absolute;
            top: 10px;
            left: 10px;
            z-index: 1;
        }

        div.ribbon:after {
            border-color: #5b31dd transparent #5b31dd #5b31dd;
            right: -20px;
            border-width: 15px;
            height: 0;
            width: 0;
            content: '';
            position: absolute;
            border-style: solid;
            top: 0px;
        }
    }

    width: 100%;
    display: flex;
    flex-direction: column;
    background: white;
    margin: 3px 0;
    border-radius: 22px;
    position: relative;
    box-sizing: border-box;
    .top_box {
        background: white;
        margin: 0px 0px;
        display: flex!important;
        align-items: center!important;
        justify-content: start!important;
        padding: 10px 0 5px 0;
        img { 
            width: 40%!important;
            height: 40%!important;
            object-fit: contain;
            
        }
    }
    .middle_box {
        background: white;
        margin: 0px 0px;
        display: flex!important;
        align-items: center!important;
        justify-content: start!important;
        padding: 5px 10px;
        img {
            // width: 133px;
            // height: 24px;
            margin-top: 5px;
            }
        .main_box {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
            .part_box1{
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                
                .part_box1_right {
                    font-family: GT Pressura,sans-serif;
                    font-weight: 700;
                    line-height: 1.33;
                    font-size: 22px;
                    letter-spacing: 0.01em;
                    color: #0f1a16;
                }
         
                div.rating {
                    text-decoration: none;
                    color: #000;
                    // margin-left: 10px;
                    display: flex;

                    span.number {
                        font-weight: 600;
                        font-size: 14px;
                    }
                    
                    span.total {
                        color: gray;
                        font-size: 14px;
                    }
                }
            }
            .part_box2{
                    margin: 0px;
                    color: #000;
                    display: -webkit-box;
                    -webkit-line-clamp: 5;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    font-family: Inter, Arial, sans-serif;
                    font-weight: 400;
                    cursor: pointer;
                    font-size: 15px;
                &:hover {
                    background-color: #edecec;
                }
            }
        }
    }
    .bottom_box {
        background: white;
        margin: 0px 0px;
        display: flex!important;
        align-items: center!important;
        justify-content: end!important;
        padding: 10px 10px;
        img {
            max-width: 100%;
            height: 30px;
            margin-top: 20px;
        }
        .MuiBox-root {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        p.picked {
            color: #999999;
            // padding-top: 10px;
            margin-top: 10px;
            // border-top: 1px solid #e2e0da;
            font-size: 13px;
            margin-bottom: 0;
            // -webkit-letter-spacing: -0.1px;
            // -moz-letter-spacing: -0.1px;
            // -ms-letter-spacing: -0.1px;
            letter-spacing: -0.1px;
            line-height: 18px;
            width: 100%;
            text-align: center;
        }
    }
    ${CustomButton} {
        width: 100%;
        padding: 10px;
        cursor: pointer;
        font-family: sans-serif;
        font-weight: 600;
        line-height: 1.33;
        font-size: 15px!important;
        letter-spacing: 0.03em!important;
        color: white!important;
        background-color: #f6ae18!important;
        border: 1px solid #f6ae18!important;
        text-transform: uppercase;
        
        &:hover {
            border: 1px solid #f2a842!important;
            background-color: #f09637!important;
        }  
    }
`

export default ProductBoxMobile