import React from "react";
import "./autoplaycarousel.css";
// import { cardDetails } from "./carousel-config";
import CarouselItem from "./CarouselItem";

const cardDetails = [
    {
        imgUrl: require('assets/slider_logos/01.jpg').default,
        title: "Text 1"
    },

    {
        imgUrl: require('assets/slider_logos/02.jpg').default,
        title: "Text 2"
    },
    {
        imgUrl: require('assets/slider_logos/03.jpg').default,
        title: "Text 3"
    },

    {
        imgUrl: require('assets/slider_logos/04.jpg').default,
        title: "Text 4"
    },

    {
        imgUrl: require('assets/slider_logos/05.jpg').default,
        title: "Text 5"
    },

    {
        imgUrl: require('assets/slider_logos/06.jpg').default,
        title: "Text 6"
    },

    {
        imgUrl: require('assets/slider_logos/07.jpg').default,
        title: "Text 7",
    },   

    {
        imgUrl: require('assets/slider_logos/08.jpg').default,
        title: "Text 8",
    },   

    {
        imgUrl: require('assets/slider_logos/09.jpg').default,
        title: "Text 9",
    },   

    {
        imgUrl: require('assets/slider_logos/10.jpg').default,
        title: "Text 10",
    }, 
    
    {
        imgUrl: require('assets/slider_logos/11.jpg').default,
        title: "Text 11",
    }, 

    {
      imgUrl: require('assets/slider_logos/12.jpg').default,
      title: "Text 12",
    }, 
    {
      imgUrl: require('assets/slider_logos/13.jpg').default,
      title: "Text 13",
    }, 
    {
      imgUrl: require('assets/slider_logos/14.jpg').default,
      title: "Text 14",
    }, 
]

export default function AutoplayCarousel() {
  return (
    <div className="carousel-container">
      <div className="carousel-track">
        {cardDetails.map((ele:any, index:number) => {
          return (
            <CarouselItem
              imgUrl={ele.imgUrl}
              imgTitle={ele.title}
              key={index}
            ></CarouselItem>
          );
        })}
        {cardDetails.map((ele:any, index:number) => {
          return (
            <CarouselItem
              imgUrl={ele.imgUrl}
              imgTitle={ele.title}
              key={index}
            ></CarouselItem>
          );
        })}
      </div>
    </div>
  );
}