import React from 'react'
import { Switch } from 'react-router'
import { Router, Route } from 'react-router-dom'
import history from '../history'
import { Paths } from './types'
import { NotFound } from './NotFound'
import { RouteContainer } from './style'
import Home from 'modules/home'
// import Home from 'modules/home_01'
// import Test from 'modules/test'
import Base from 'modules/base'
import Base_New from 'modules/base_new'
import AsinProduct from 'modules/asinProduct'
// import Admin from 'modules/admin_01'
import Admin from 'modules/admin'

import TermsOfUse from 'modules/terms_of_use'
import PrivacyPolicy from 'modules/privacy_policy'
import Data from 'modules/record_data'
import Footer from 'modules/app/components/footer'
// import Footer from 'modules/app/components/footer_01'
import SignIn from 'modules/signin/Signin'
import Quiz from 'modules/quiz/user_quiz/quiz_main'
import Admin_Simulation_Quiz from 'modules/quiz/admin_simulation_quiz'
import Quiz_list from 'modules/quiz/user_quiz/quiz_list'
import Admin_Examples_For_Finetuning from 'modules/quiz/admin_examples_for_finetuning'
import Admin_Quiz from 'modules/quiz/admin_quiz'
import Admin_Quiz_Analyze from 'modules/quiz/admin_quiz_analyze'
import Admin_Quiz_Analyze_Users from 'modules/quiz/admin_quiz_analyze_users'
import { Header, MobileHeader } from 'modules/app/components/header'
// import { Header, MobileHeader } from 'modules/app/components/header_01'

const notFoundRoute: RouteDefinition = {
  path: '*',
  component: NotFound,
  protected: false,
  title: '',
}

export const routes: RouteDefinition[] = [
  {
    path: Paths.root,
    component: Home,
    protected: false,
    redirect: Paths.root,
  },
  // {
  //   path: Paths.base,
  //   component: Base,
  //   protected: false,
  //   redirect: Paths.root,
  // },
  {
    path: Paths.base,
    component: Base_New,
    protected: false,
    redirect: Paths.root,
  },
  // {
  //   path: Paths.base,
  //   component: Base,
  //   protected: false,
  //   redirect: Paths.root,
  // },
  {
    path: Paths.admin_product,
    component: Admin,
    protected: false,
    redirect: Paths.root,
  },
  // {
  //   path: Paths.terms_of_use,
  //   component: TermsOfUse,
  //   protected: false,
  //   redirect: Paths.root,
  // },
  // {
  //   path: Paths.privacy_policy,
  //   component: PrivacyPolicy,
  //   protected: false,
  //   redirect: Paths.root,
  // },
  {
    path: Paths.data,
    component: Data,
    protected: false,
    redirect: Paths.root,
  },
  {
    path: Paths.admin_signin,
    component: SignIn,
    protected: false,
    redirect: Paths.root,
  },
  // {
  //   path: Paths.product_by_asin,
  //   component: AsinProduct,
  //   protected: false,
  //   redirect: Paths.root,
  // },
  {
    path: Paths.quiz,
    component: Quiz,
    protected: false,
    redirect: Paths.root,
  },
  {
    path: Paths.admin_simulation_quiz,
    component: Admin_Simulation_Quiz,
    protected: false,
    redirect: Paths.root,
  },
  // {
  //   path: Paths.quiz_old,
  //   component: Quiz_Old,
  //   protected: false,
  //   redirect: Paths.root,
  // },
  {
    path: Paths.admin_quiz,
    component: Admin_Quiz,
    protected: false,
    redirect: Paths.root,
  },
  {
    path: Paths.admin_quiz_analyze,
    component: Admin_Quiz_Analyze,
    protected: false,
    redirect: Paths.root,
  },
  {
    path: Paths.admin_quiz_analyze_users,
    component: Admin_Quiz_Analyze_Users,
    protected: false,
    redirect: Paths.root,
  },
  {
    path: Paths.admin_examples_for_finetuning_training,
    component: Admin_Examples_For_Finetuning,
    protected: false,
    redirect: Paths.root,
  },
  // {
  //   path: Paths.quiz_list,
  //   component: Quiz_list,
  //   protected: false,
  //   redirect: Paths.root,
  // },
].concat(notFoundRoute as any) // Ensure that notFound is the last route

export interface RouteDefinition {
  path: string
  protected?: boolean
  redirect?: string
  component?: any
  routes?: RouteDefinition[]
  title?: string
  pathType?: number
}

interface Props {}
interface RoutesProps {}

function getRouteRenderWithAuth(route: RouteDefinition, i: number) {
  return () => <route.component />
}

const Routes: React.FC<Props & RoutesProps> = () => {
  return (
    <Router history={history}>
      <RouteContainer>
        <Header />
        <MobileHeader />
        <Switch>
          {routes.length > 0 &&
            routes.map((route, i) => {
              const render = getRouteRenderWithAuth(route, i)
              const rest = { render }
              
              return <Route key={i} path={route.path} exact {...rest} />
            })}
        </Switch>
        <Footer />
      </RouteContainer>
    </Router>
  )
}

export default Routes
