import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components'
import './home.css'
import { Grid } from '@mui/material'
import AutoplayCarousel from './comps/AutoplayCarousel'
import { screenSizes } from 'shared/styles/theme'

const Home = (props: any) => {

    const [isTooltipVisible, setIsTooltipVisible] = useState<boolean>(false);
    const [isHoverDisabled, setIsHoverDisabled] = useState<boolean>(false);
    const tooltipRef = useRef<HTMLDivElement | null>(null);

    const handleClick = (e: React.MouseEvent): void => {
        e.stopPropagation();
        setIsHoverDisabled(true);
        // setIsTooltipVisible((prev) => !prev);
      };
    
    // Enables the hover behavior when clicking outside
    const handleClickOutside = (e: MouseEvent): void => {
        if (
            tooltipRef.current &&
            !tooltipRef.current.contains(e.target as Node)
        ) {
            setIsTooltipVisible(false);
            setIsHoverDisabled(false);
        }
    };

    const click_got_it = () => {
        setIsTooltipVisible(false);
        setIsHoverDisabled(false);
    }

    // Set up a global click listener to hide the tooltip
    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (
        <>
         <CustomWrapperInit>
           <div className='section_report'>
                <div className='container_grid'>
                    <CustomGrid container spacing={0}>
                        <CustomGrid item xs={12} md={6} style={{padding: 0}}>
                            <h3 className="section_report_uptitle">Compare Top Products With</h3>
                            <h2 className="section_report_downtitle">ProductWiz</h2>
                        </CustomGrid>
                        <CustomGrid item xs={12} md={6} style={{padding: 0}} className="advertising_grid">
                            <div className='advertising_grid_wrapper'>
                                <div 
                                    className='advertising_grid_text'
                                    onMouseEnter={() => !isHoverDisabled && setIsTooltipVisible(true)}
                                    onMouseLeave={() => !isHoverDisabled && setIsTooltipVisible(false)}
                                    onClick={handleClick}
                                >
                                    Advertising Disclosure &nbsp;
                                    <img src={require('assets/arrow_bottom_buyer.svg').default} />
                                </div>
                                {
                                    isTooltipVisible && (
                                        <div className='advertising_text_tooltip'  ref={tooltipRef}>
                                            <p>This site is a free online resource that strives to offer helpful content and comparison features to its visitors. Please be advised that the operator of this site accepts advertising compensation from companies that appear on the site, and such compensation  impacts the location and order in which the companies (and/or their products).</p>
                                            <p>In addition, the Operating Agreement requires that the following statement clearly and conspicuously appears on your Site: &quot;As an Amazon Associate I earn from qualifying purchases.&quot; For social media user-generated content, this statement must be associated with your account.</p>  
                                            <div className='advertising_got_it_btn' onClick={click_got_it}>
                                                GOT IT
                                            </div>
                                        </div>
                                    )
                                }
                                
                            </div>
                        </CustomGrid>
                    </CustomGrid>

                    <div className='popular_comparisons equal_px_grid'>
                        <div className='comparison_grid'>
                            <div className="comparison_card">
                                <a href="/home-air-purifiers" className="comparison_card_a">
                                    <div className="comparison_image">
                                        <img alt="Best Home Air Purifiers" src={require('assets/homepage_images/home_air_purifiers.png').default} />
                                        <div className="comparison_overlay">
                                            <div className="comparison_text">
                                                <span className="best_label">BEST</span>
                                                <h3>Home Air Purifiers</h3>
                                            </div>
                                        </div>
                                    </div>
                                </a>            
                            </div>

                            <div className="comparison_card">
                                <a href="/ninja-coffee-makers" className="comparison_card_a">
                                    <div className="comparison_image">
                                        <img alt="Best Ninja Coffee Makers" src={require('assets/homepage_images/ninja_coffee_makers.png').default} />
                                        <div className="comparison_overlay">
                                            <div className="comparison_text">
                                                <span className="best_label">BEST</span>
                                                <h3>Ninja Coffee Makers</h3>
                                            </div>
                                        </div>
                                    </div>
                                </a>            
                            </div>

                            <div className="comparison_card">
                                <a href="/star-wars-lego-sets" className="comparison_card_a">
                                    <div className="comparison_image">
                                        <img alt="Best Star Wars LEGO Sets" src={require('assets/homepage_images/star_wars_lego_sets.png').default} />
                                        <div className="comparison_overlay">
                                            <div className="comparison_text">
                                                <span className="best_label">BEST</span>
                                                <h3>Star Wars LEGO Sets</h3>
                                            </div>
                                        </div>
                                    </div>
                                </a>            
                            </div>

                            

                            <div className="comparison_card">
                                <a href="/polaroid-cameras" className="comparison_card_a">
                                    <div className="comparison_image">
                                        <img alt="Best Polaroid Cameras" src={require('assets/homepage_images/polaroid_cameras.png').default} />
                                        <div className="comparison_overlay">
                                            <div className="comparison_text">
                                                <span className="best_label">BEST</span>
                                                <h3>Polaroid Cameras</h3>
                                            </div>
                                        </div>
                                    </div>
                                </a>            
                            </div>

                            <div className="comparison_card">
                                <a href="/coffee-grinders" className="comparison_card_a">
                                    <div className="comparison_image">
                                        <img alt="Best Coffee Grinders" src={require('assets/homepage_images/coffee_grinders.png').default} />
                                        <div className="comparison_overlay">
                                            <div className="comparison_text">
                                                <span className="best_label">BEST</span>
                                                <h3>Coffee Grinders</h3>
                                            </div>
                                        </div>
                                    </div>
                                </a>            
                            </div>

                           

                            
                        </div>

                    </div>

                    <div className='container_carousel_div equal_px_grid'>
                        <div className='carousel_wrapper_div'>
                            <AutoplayCarousel />

                        </div>
                    </div>
                </div>
           </div>

           <div className='section_store'>
                <div className='store_container'>
                    <div className='store_flex_container equal_px_grid'>
                        <div className='store_header'>
                            <div className="store_titles_section">
                                <h2>Featured Products</h2>
                                <h3 className="store_subtitle">
                                    Finding the right products shouldn't be a hassle. We're here to help you save time and 
                                    make informed purchasing decisions.
                                </h3>
                            </div>

                            <div className="store_nav_ection">
                            </div>
                        </div>

                        <div className='store_wrapper_carousel'>
                            <ul className='store_wrapper_carousel_ul'>
                                <li className="cardjs">
                                    <div className="cover">
                                        <img alt="Electronics" draggable="false" width="353" src="https://www.buyersreport.org/assets/br/images/electronics-0d41c6ea3952de1a644ec59a18fbfb8a7d73a8ce9c5b7381af55ff52399dca5b.jpg" />
                                    </div>
                                    <div className="info">
                                        <p className="store_category_title">Electronics</p>
                                        <span>
                                            <span><a className="store_children_categories" href="/">Air Purifiers</a></span>
                                            <span><a className="store_children_categories" href="/">Foot Massagers</a></span>
                                            <span><a className="store_children_categories" href="/">Robot Vacuums</a></span>
                                            <span><a className="store_children_categories" href="/">Massage Guns</a></span>
                                            <span><a className="store_children_categories" href="/">Dash Cams</a></span>
                                        </span>
                                    </div>
                                </li>

                                <li className="cardjs">
                                    <div className="cover">
                                        <img alt="Electronics" draggable="false" width="353" src="https://www.buyersreport.org/assets/br/images/home-kitchen-f0de4df61827c9c39b8da612ec06bf8a133c873aba8fd8d7610433ee4422f136.jpg" />
                                    </div>
                                    <div className="info">
                                        <p className="store_category_title">Home & Kitchen</p>
                                        <span>
                                            <span><a className="store_children_categories" href="/">Espresso Machines</a></span>
                                            <span><a className="store_children_categories" href="/">Smart Locks</a></span>
                                            <span><a className="store_children_categories" href="/">Humidifiers</a></span>
                                            <span><a className="store_children_categories" href="/">Streaming Devices</a></span>
                                            <span><a className="store_children_categories" href="/">Digital Picture Frames</a></span>
                                        </span>
                                    </div>
                                </li>

                                <li className="cardjs">
                                    <div className="cover">
                                        <img alt="Electronics" draggable="false" width="353" src="https://www.buyersreport.org/assets/br/images/health-beauty-f24930f810f56e0cda1c302412e88aedebd81de7d7ad25f22e39131e0260d57a.jpg" />
                                    </div>
                                    <div className="info">
                                        <p className="store_category_title">Health & Beauty</p>
                                        <span>
                                            <span><a className="store_children_categories" href="/">Teeth Whitening</a></span>
                                            <span><a className="store_children_categories" href="/">Laser Hair Removal Devices</a></span>
                                            <span><a className="store_children_categories" href="/">Face Moisturizers</a></span>
                                            <span><a className="store_children_categories" href="/">Shampoo Conditioner Sets</a></span>
                                            <span><a className="store_children_categories" href="/">Anti Aging Serums</a></span>
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </div>

                        
                    </div>
                </div>
           </div>

            <div className='section_buyer'>
                <div className='buyer_container'>
                    <CustomGrid container spacing={0} className='buyer_flex_container'>
                        <CustomGrid item xs={12} md={5} className='buyer_flex_sub_grid_5'>
                            <div className="buyer_reverse">
                                <div className='buyer_reverse_text_container'>
                                    <h2>Product Recommendations Backed By Performance</h2>
                                    <p>
                                        Our team of experts evaluate thousands of products based on real user 
                                        feedback, quality, price, and on-site performance. We take the time to 
                                        discover great products from quality brands so you can feel confident in 
                                        your purchase.
                                    </p>
                                    <p>Take the guesswork out of shopping. Explore our carefully selected products today and find what’s right for you.</p>
                                </div>
                                <img src="https://www.buyersreport.org/assets/br/images/home-display2-abfec40aed58bb3f8fa55902631fb4d046ce353a4dd26bc1aa4152d3723fae3b.jpg" />
                            </div>
                        </CustomGrid>
                        <CustomGrid item xs={12} md={7} className='buyer_flex_sub_grid_7'>
                            <img src="https://www.buyersreport.org/assets/br/images/home-display1-65a902f4191d33cb9b558593046e855591c1d7b8f1095c4dcb7e60d10885397e.jpg" />
                            <div className="buyer_m_t_50">
                                <h2>Top Sellers, Unbeatable Deals</h2>
                                <p>
                                    Say goodbye to endless scrolling and time consuming research. We partner with 
                                    trusted sellers to offer you great value and reliable recommendations across 
                                    hundreds of product categories.
                                </p>
                            </div>
                        </CustomGrid>
                    </CustomGrid>
                </div>
            </div>

            <div className='section_subscribe'>
                <div className='subscribe_container'>
                    <h2>
                        Discover great products today.
                    </h2>
                </div>
            </div>

        </CustomWrapperInit>
        </>                                                             
    );
};

const CustomWrapperInit = styled.div`
    
`

const CustomGrid = styled(Grid)`
  background-color: white!important;
  padding: 8px 20px;
  margin: 0px!important;

    @media (max-width: ${screenSizes.mediaM}px) {
        padding: 8px 0px;
    }

  &.advertising_grid {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    div.advertising_grid_wrapper {
        position: relative;
    }

    div.advertising_grid_text {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        font-size: 14px;
        color: rgb(113, 110, 118);
    }
    
    div.advertising_text_tooltip {
        right: -40%;
        top: 45px;
        position: absolute;
        z-index: 2;
        background-color: #f1efe9;
        width: 160%;
        padding: 5px 20px 15px;
        color: #000;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.3rem;
        text-align: center;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: default;

        @media (max-width: ${screenSizes.mediaM}px) {
            right: 0%;
        }
    }

    div.advertising_got_it_btn {
        padding: 5px 10px;
        background-color: #f38009;
        border-radius: 5px;
        color: white;
        width: 80%;
        cursor: pointer;
    }

    div.advertising_got_it_btn:hover {
        background-color: #f79e41;
       
    }
  }

  &.buyer_flex_container {
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    align-items: center !important;

    @media (max-width: ${screenSizes.mediaM}px) {
        flex-direction: column-reverse;
    }
  }

  &.buyer_flex_sub_grid_5 {
    padding: 0 12px 0 0!important;

    @media (max-width: ${screenSizes.mediaM}px) {
        padding: 0 12px!important;
       
    }
  }

  &.buyer_flex_sub_grid_7 {
    padding: 0 0 0 12px!important;

    @media (max-width: ${screenSizes.mediaM}px) {
        padding: 0 12px!important;
    }
  }
`
export default Home