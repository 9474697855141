import React, { useEffect } from 'react'
import { styled } from '@mui/material/styles';
import styled1 from 'styled-components'
import { Grid, Box } from '@mui/material'
import { screenSizes } from 'shared/styles/theme'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import history from 'modules/app/components/history'
import jwt_decode from 'jwt-decode'
import { read_all_quiz, read_quiz_by_id } from 'modules/api/market-api'
import MobileHeader from './mobile_page/MobileHeader'
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import NotesIcon from '@mui/icons-material/Notes';
import DeleteUserOfQuizDialog from './sub_components/DeleteUserOfQuizDialog';
import GenerateExampleDialog from './sub_components/GenerateExampleDialog';
import AdminHeader from 'shared/AdminHeader'
import Container from '@mui/material/Container';
import Test from 'modules/test'
import Toolbar from '@mui/material/Toolbar';

const Row = (props: { row: any, question_arr_length: any, clicked_delete_quiz:any, clicked_generate_example:any }) => {
  const { row, question_arr_length, clicked_delete_quiz, clicked_generate_example } = props;
  // console.log(row)
  const [open, setOpen] = React.useState(false);
  const [is_gen_example_btn, setGenExampleBtn] = React.useState(false)

  useEffect(() => {
    let temp_choices_array = JSON.parse(JSON.stringify(row.choice_arr))
    // console.log(temp_choices_array)
    temp_choices_array.shift()
    // console.log(temp_choices_array)

    let isContainEmptyStr = temp_choices_array.includes('')

    if (isContainEmptyStr === false && row.generated_result_text_gpt_4 !== '' && row.model_name !== '') setGenExampleBtn(true)
  }, [])

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.user_name}
        </TableCell>
        {
          row.choice_arr.map((ele:any, index:number) => {
            return <CustomTableCell key={index} align="center" className={index === 0 ? 'question_t_head_first_screener' : 'question_t_head'}>{ele}</CustomTableCell>
          })
        }
        <TableCell component="th" scope="row">
          <CustomDiv className={"min-width-0 delete_icon"} onClick={(e:any) => clicked_delete_quiz(e, row.user_name)}>
              <DeleteOutlineIcon fontSize="small" />
          </CustomDiv>
        </TableCell>

        <TableCell component="th" scope="row">
          {
            is_gen_example_btn === false 
            ? <></>
            : <CustomDiv className={"min-width-0 delete_icon"} onClick={(e:any) => clicked_generate_example(e, row)}>
                <NotesIcon fontSize="small" />
              </CustomDiv>  
          }
          
        </TableCell>

      </TableRow>
      <CustomTableRow className={'user_info_row'}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={question_arr_length + 3}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {/* <Box sx={{ margin: 1 }}>
              {
                row.best_url === undefined ? 
                <Table size="small" aria-label="purchases" sx={{  tableLayout: 'fixed'}}>
                <TableHead>
                  <TableRow>
                    <HeadTableCell align="center" colSpan={2}>Selected Model Name</HeadTableCell>
                    <HeadTableCell align="center" colSpan={6}>Visited link</HeadTableCell>
                    <HeadTableCell align="center" colSpan={2}>Time Stamp</HeadTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow >
                    <TableCell align="center" colSpan={2}>{row.model_name === undefined || row.model_name.trim() === '' ? '-' : row.model_name}</TableCell>
                    <TableCell align="center" colSpan={6}>
                      <Table size="small" aria-label="purchases" sx={{ tableLayout: 'fixed', overflowWrap: 'break-word'}}>
                        <TableHead>
                          <TableRow>
                            <HeadTableCell align="center" colSpan={4}>Visited link on text</HeadTableCell>
                            <HeadTableCell align="center" colSpan={1}>Count</HeadTableCell>
                            <HeadTableCell align="center" colSpan={4}>Visited likn on image</HeadTableCell>
                            <HeadTableCell align="center" colSpan={1}>Count</HeadTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {
                            row.links_on_text.length === 0 && row.links_on_image.length === 0 ? 
                            <>
                              <TableRow>
                                  <TableCell align="center" colSpan={4}>-</TableCell>
                                  <TableCell align="center" colSpan={1}>-</TableCell>
                                  <TableCell align="center" colSpan={4}>-</TableCell>
                                  <TableCell align="center" colSpan={1}>-</TableCell>
                                </TableRow>
                            </> :
                            row.links_on_image.length === 0 ? 
                            row.links_on_text.map((ele:any, index:number) => {
                              return (
                                <TableRow key={index}>
                                  <TableCell align="center" colSpan={4}>{ele.link_str}</TableCell>
                                  <TableCell align="center" colSpan={1}>{ele.count}</TableCell>
                                  <TableCell align="center" colSpan={4}>-</TableCell>
                                  <TableCell align="center" colSpan={1}>-</TableCell>
                                </TableRow>
                              )
                            }) : 
                            row.links_on_text.length === 0 ? 
                            row.links_on_image.map((ele:any, index:number) => {
                              return (
                                <TableRow key={index}>
                                  <TableCell align="center" colSpan={4}>-</TableCell>
                                  <TableCell align="center" colSpan={1}>-</TableCell>
                                  <TableCell align="center" colSpan={4}>{ele.link_str}</TableCell>
                                  <TableCell align="center" colSpan={1}>{ele.count}</TableCell>
                                </TableRow>
                              )
                            }) : 
                            row.links_on_image.map((ele:any, index:number) => {
                              return (
                                <TableRow key={index}>
                                  <TableCell align="center" colSpan={4}>{row.links_on_text[index].link_str}</TableCell>
                                  <TableCell align="center" colSpan={1}>{row.links_on_text[index].count}</TableCell>
                                  <TableCell align="center" colSpan={4}>{ele.link_str}</TableCell>
                                  <TableCell align="center" colSpan={1}>{ele.count}</TableCell>
                                </TableRow>
                              )
                            })
                          }
                          
                        </TableBody>
                      </Table>  
                    </TableCell>
                    <TableCell align="center" colSpan={2}>{row.time_stamp}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              :
              <Table size="small" aria-label="purchases" sx={{  tableLayout: 'fixed'}}>
                <TableHead>
                  <TableRow>
                    <HeadTableCell align="center" colSpan={5}>First Screener Url</HeadTableCell>
                    <HeadTableCell align="center" colSpan={5}>Time Stamp</HeadTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow >
                    <TableCell align="center" colSpan={5}>{row.best_url}</TableCell>
                    <TableCell align="center" colSpan={5}>{row.time_stamp}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              }
              
            </Box> */}

            <Box sx={{ margin: 1 }}>
              {
                row.best_url === undefined ? 
                <Table size="small" aria-label="purchases" sx={{  tableLayout: 'fixed'}}>
                <TableHead>
                  <TableRow>
                    {/* <HeadTableCell align="center" colSpan={2.5}>Selected Model Name</HeadTableCell>
                    <HeadTableCell align="center" colSpan={2.5}>Generated Result Text GPT 3.5</HeadTableCell>
                    <HeadTableCell align="center" colSpan={2.5}>Generated Result Text GPT 4</HeadTableCell>
                    <HeadTableCell align="center" colSpan={2.5}>Time Stamp</HeadTableCell> */}
                    <HeadTableCell align="center" colSpan={3}>Selected Model Name</HeadTableCell>
                    {/* <HeadTableCell align="center" colSpan={2.5}>Generated Result Text GPT 3.5</HeadTableCell> */}
                    <HeadTableCell align="center" colSpan={4}>Generated Result Text</HeadTableCell>
                    <HeadTableCell align="center" colSpan={3}>Time Stamp</HeadTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow >
                    {/* <TableCell align="center" colSpan={2.5}>{row.model_name === undefined || row.model_name.trim() === '' ? '-' : row.model_name}</TableCell>                  
                    <TableCell style={{textAlign: 'left', whiteSpace: 'pre-wrap', verticalAlign: 'baseline'}} align="center" colSpan={2.5}>{row.generated_result_text === undefined || row.generated_result_text.trim() === '' ? '-' : row.generated_result_text}</TableCell>
                    <TableCell style={{textAlign: 'left', whiteSpace: 'pre-wrap', verticalAlign: 'baseline'}} align="center" colSpan={2.5}>{row.generated_result_text_gpt_4 === undefined || row.generated_result_text_gpt_4.trim() === '' ? '-' : row.generated_result_text_gpt_4}</TableCell>
                    <TableCell align="center" colSpan={2.5}>{row.time_stamp}</TableCell> */}
                    <TableCell align="center" colSpan={3}>{row.model_name === undefined || row.model_name.trim() === '' ? '-' : row.model_name}</TableCell>                  
                    {/* <TableCell style={{textAlign: 'left', whiteSpace: 'pre-wrap', verticalAlign: 'baseline'}} align="center" colSpan={2.5}>{row.generated_result_text === undefined || row.generated_result_text.trim() === '' ? '-' : row.generated_result_text}</TableCell> */}
                    <TableCell style={{textAlign: 'left', whiteSpace: 'pre-wrap', verticalAlign: 'baseline'}} align="center" colSpan={4}>{row.generated_result_text_gpt_4 === undefined || row.generated_result_text_gpt_4.trim() === '' ? '-' : row.generated_result_text_gpt_4}</TableCell>
                    <TableCell align="center" colSpan={3}>{row.time_stamp}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              :
              <Table size="small" aria-label="purchases" sx={{  tableLayout: 'fixed'}}>
                <TableHead>
                  <TableRow>
                    <HeadTableCell align="center" colSpan={5}>First Screener Url</HeadTableCell>
                    <HeadTableCell align="center" colSpan={5}>Time Stamp</HeadTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow >
                    <TableCell align="center" colSpan={5}>{row.best_url}</TableCell>
                    <TableCell align="center" colSpan={5}>{row.time_stamp}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              }
              
            </Box>

            <Box sx={{ margin: 1 }}>
            {
                row.best_url === undefined ?
              <Table size="small" aria-label="purchases" sx={{  tableLayout: 'fixed'}}>
                <TableHead>
                  <TableRow>
                  <HeadTableCell align="center" colSpan={3}>Visited link on text</HeadTableCell>
                  <HeadTableCell align="center" colSpan={0.3}>Count</HeadTableCell>
                  <HeadTableCell align="center" colSpan={3}>Visited link on image</HeadTableCell>
                  <HeadTableCell align="center" colSpan={0.3}>Count</HeadTableCell>
                  <HeadTableCell align="center" colSpan={3}>Visited link on button</HeadTableCell>
                  <HeadTableCell align="center" colSpan={0.3}>Count</HeadTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow >
                    <TableCell style={{wordBreak: 'break-word'}} align="center" colSpan={3}>{row.links_on_text.length !== 0 ? row.links_on_text[0].link_str : '-'}</TableCell>
                    <TableCell style={{wordBreak: 'break-word'}} align="center" colSpan={0.3}>{row.links_on_text.length !== 0 ? row.links_on_text[0].count : '-'}</TableCell>
                    <TableCell style={{wordBreak: 'break-word'}} align="center" colSpan={3}>{row.links_on_image.length !== 0 ? row.links_on_image[0].link_str : '-'}</TableCell>
                    <TableCell style={{wordBreak: 'break-word'}} align="center" colSpan={0.3}>{row.links_on_image.length !== 0 ? row.links_on_image[0].count : '-'}</TableCell>
                    <TableCell style={{wordBreak: 'break-word'}} align="center" colSpan={3}>{row.links_on_btn.length !== 0 ? row.links_on_btn[0].link_str : '-'}</TableCell>
                    <TableCell style={{wordBreak: 'break-word'}} align="center" colSpan={0.3}>{row.links_on_btn.length !== 0 ? row.links_on_btn[0].count : '-'}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
               :
               <></>
               }
            </Box>

            <Box sx={{ margin: 1 }}>
              {
                row.best_url === undefined ? 
                <Table size="small" aria-label="purchases" sx={{tableLayout: 'fixed'}}>
                <TableHead>
                  <TableRow>
                    <HeadTableCell align="center" colSpan={2.5}>First Name</HeadTableCell>
                    <HeadTableCell align="center" colSpan={2.5}>Last Name</HeadTableCell>
                    <HeadTableCell align="center" colSpan={2.5}>Email</HeadTableCell>
                    <HeadTableCell align="center" colSpan={2.5}>Phone Number</HeadTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow >
                    <TableCell align="center" colSpan={2.5}>{row.first_name === undefined || row.first_name.trim() === '' ? '-' : row.first_name}</TableCell>
                    <TableCell align="center" colSpan={2.5}>{row.last_name === undefined || row.last_name.trim() === '' ? '-' : row.last_name}</TableCell>
                    <TableCell align="center" colSpan={2.5}>{row.email === undefined || row.email.trim() === '' ? '-' : row.email}</TableCell>
                    <TableCell align="center" colSpan={2.5}>{row.phone_number === undefined || row.phone_number.trim() === '' ? '-' : row.phone_number}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              :
              <></>
              }
              
            </Box>
          </Collapse>
        </TableCell>
      </CustomTableRow>
    </React.Fragment>
  );
}

const Admin_Quiz_Analyze_Users = (props: any) => {
  const [ isLoading, setIsLoading ] = React.useState<boolean>(false)
  const [ isFirstLoading, setIsFirstLoading ] = React.useState<boolean>(false)

  const [ products, setProducts ] = React.useState<any>([])
  const [ best_url, setBestUrl ] = React.useState<string>('')
  const [ best_url_visited_users, setBestUrlVisitedUsers ] = React.useState<any>([])
  const [ question_arr, setQuestionArr ] = React.useState<any>([])
  const [ user_and_choice_arr, setUserAndChoiceArr ] = React.useState<any>([])
  const [ deleteUserOfQuizDialogOpen, setDeleteUserOfQuizDialogOpen ] = React.useState<any>(false)
  const [ generateExampleDialogOpen, setGenerateExampleDialogOpen ] = React.useState<any>(false)
  const [ selected_product_index, setSelectedProductIndex ] = React.useState<number>(-1)
  const [ selected_row, setSelectedRow ] = React.useState<any>({})
  const [ del_user_name, setDelUserName ] = React.useState<string>('')

  useEffect(() => {
    setIsFirstLoading(true)

    if ( localStorage.getItem('jwtToken') != null && localStorage.getItem('userDbId') != null ) {
      const decoded:any = jwt_decode(JSON.stringify(localStorage.getItem('jwtToken')))

      if ( decoded.id == localStorage.getItem('userDbId') ) {
        (async () => {
          const result:any = await read_all_quiz()
          // console.log(result)
          if( result.status === 'success' ) {
              let products = result.result
              products = products.map((product:any, index:number) => {
                  product = Object.assign(product, {active: false})
                  return product
              })
              setProducts(products)

              setIsFirstLoading(false)

          } else if ( result.status === 'error' ) {
            setIsFirstLoading(false)

              history.push('/admin/signin')
          }
        })()
      } else {
        setIsFirstLoading(false)

        history.push(`/admin/signin`)
      }

    } else {
      setIsFirstLoading(false)

      history.push(`/admin/signin`)
    }
  }, [])

  useEffect(() => {
    (async () => {
      try {
        // console.log('set products ... ')
        setIsLoading(true)
          let active_product_index = products.findIndex((ele:any) => ele.active === true)
          // console.log(active_product_index)
          if ( active_product_index !== -1 ) {
            // console.log(products[active_product_index])
            // console.log(products[active_product_index]._id)

            // let result = await read_quiz_by_id(products[active_product_index]._id)

            // let best_url = result.best_url             
            // let quiz = result.quiz
            // let users = result.users

            let best_url = products[active_product_index].best_url             
            let quiz = products[active_product_index].quiz
            let users = products[active_product_index].users
            let models = products[active_product_index].models

            // console.log(models)
            // console.log(quiz)
            // console.log(users)
            // console.log(quiz)

            let question_arr = quiz.map((ele:any, index:number) => {
              return ele.question
            })

            // console.log(question_arr)
            question_arr.unshift('First screener')
            setQuestionArr(question_arr)

            let users_arr:any = []
            quiz.map((ele:any, index:number) => {
              let temp_user_arr = []
              if ( ele.users !== undefined ) {
                temp_user_arr = ele.users.map((ele1:any, index:number) => {
                  // return ele1.user_name
                  return { 
                    user_name: ele1.user_name,
                    user_info: ele1.user_info
                  }
                })
              }                

              users_arr = users_arr.concat(temp_user_arr)
            })

            users_arr = users_arr.filter((value:any, index:number, self:any) =>
              index === self.findIndex((t:any) => (
                t.user_name === value.user_name
              ))
            )

            let user_and_choice_arr:any = []

            for ( let i = 0; i < users_arr.length; i ++ ) {
              let temp_choices_array:any = []
              for ( let j = 0; j < quiz.length; j ++ ) {
                let filtered_users_arr = []
                if ( quiz[j].users !== undefined ) {
                  filtered_users_arr = quiz[j].users.filter((ele:any, index:number) => {
                    return ele.user_name === users_arr[i].user_name
                  });
                }              

                if ( filtered_users_arr.length > 0 ) {
                  temp_choices_array.push(filtered_users_arr[0].choice)
                } else {
                  temp_choices_array.push('')
                }
              }
              temp_choices_array.unshift('')

              let temp_model_name = ''
              // let temp_model_image_url = ''
              // let temp_model_product_url = ''
              let temp_generated_result_text = ''
              let temp_generated_result_text_gpt_4 = ''
              let links_on_text = []
              let links_on_image = []
              let links_on_btn = []
              let time_stamp = ''
              let first_name = ''
              let last_name = ''
              let email = ''
              let phone_number = ''

              for ( let j = 0; j < users.length; j ++ ) {
                if ( users[j].user_name === users_arr[i].user_name) {
                  temp_model_name = users[j].model_name

                  // if (temp_model_name !== '') {
                  //   for (let i = 0; i < models.length; i ++) {
                  //     if (models[i].model_name === temp_model_name) {
                  //       temp_model_image_url = models[i].image_url
                  //       temp_model_product_url = models[i].product_url
                  //     } else {

                  //     }
                  //   }
                  // }

                  if ( users[j].generated_result_text !== undefined ) temp_generated_result_text = users[j].generated_result_text
                  if ( users[j].generated_result_text_gpt_4 !== undefined ) temp_generated_result_text_gpt_4 = users[j].generated_result_text_gpt_4
                  if ( users[j].link.links_on_text !== undefined ) links_on_text = users[j].link.links_on_text
                  if ( users[j].link.links_on_image !== undefined ) links_on_image = users[j].link.links_on_image
                  if ( users[j].link.links_on_btn !== undefined ) links_on_btn = users[j].link.links_on_btn
                  if ( users[j].time_stamp !== undefined ) time_stamp = users[j].time_stamp
                  if ( users[j].first_name !== undefined ) first_name = users[j].first_name
                  if ( users[j].last_name !== undefined ) last_name = users[j].last_name
                  if ( users[j].email !== undefined ) email = users[j].email
                  if ( users[j].phone_number !== undefined ) phone_number = users[j].phone_number

                  break;
                } 
              }

              user_and_choice_arr.push({
                user_name: users_arr[i].user_name,
                choice_arr: temp_choices_array,
                user_info: users_arr[i].user_info,
                model_name: temp_model_name,
                models: models,
                // model_image_url: temp_model_image_url,
                // model_product_url: temp_model_product_url,
                generated_result_text: temp_generated_result_text,
                generated_result_text_gpt_4: temp_generated_result_text_gpt_4,
                links_on_text: links_on_text,
                links_on_image: links_on_image,
                links_on_btn: links_on_btn,
                time_stamp: time_stamp,
                first_name: first_name,
                last_name: last_name,
                email: email,
                phone_number: phone_number,
              })
            }

            if ( best_url !== undefined ) {
              
              let tmp_arr = Array.from({ length: quiz.length }, () => '')
              // tmp_arr.unshift('visited')
              tmp_arr.unshift('skipped quiz')

              let best_url_users = best_url.users
              for ( let i = 0; i < best_url_users.length; i ++ ) {
                user_and_choice_arr.push({
                  user_name: best_url_users[i].user_name,
                  choice_arr: tmp_arr,
                  user_info: {},
                  model_name: '',
                  generated_result_text: '',
                  generated_result_text_gpt_4: '',
                  links_on_text: '',
                  links_on_image: '',
                  links_on_btn: '',
                  time_stamp: best_url_users[i].time_stamp,
                  first_name: '',
                  last_name: '',
                  email: '',
                  phone_number: '',
                  best_url: best_url.best_url,
                })
              }
            } else {
              
            }

            // console.log(user_and_choice_arr)
            user_and_choice_arr.sort((a:any, b:any) => new Date(a.time_stamp).getTime() - new Date(b.time_stamp).getTime())
            setUserAndChoiceArr(user_and_choice_arr)
            
          } else if ( active_product_index === -1 ) {
            // setSelectedProduct(null)
          }
        setIsLoading(false)
      } catch ( e ) {
        // console.log(e)
        history.push(`/admin/signin`)
      }
    })()
  }, [products])


  const goStartPage = () => {
    history.push(`/`)
  }

  const clicked_product = (active: boolean, index: number, product_name: string, product_id: string) => {
    (async () => {
      // console.log('click products ... ')
      // setUserAndChoiceArr([])
      // setQuestionArr([])
      if ( active === true ) {
        // console.log('active ... ')
      } else if ( active === false ) {
        setUserAndChoiceArr([])
        setQuestionArr([])

        setIsLoading(true)
        const result:any = await read_all_quiz()
        // console.log(result)
        if( result.status === 'success' ) {
            let products = result.result

            products = products.map((product:any, index:number) => {
                product = Object.assign(product, {active: false})
                return product
            })

            for ( let i = 0; i < products.length; i++ ) {
              if ( products[i]._id === product_id ) {
                // products[i].active = true
                products[i] = Object.assign(products[i], {active: true})
              } else {
                products[i] = Object.assign(products[i], {active: false})
              }
            }

            setProducts(products)

            setIsLoading(false)

        } else if ( result.status === 'error' ) {
          setIsLoading(false)

          history.push('/admin/signin')
        }
        // setProducts((prev: any[]) => {
        //   let next = [...prev]

        //   let prev_active_index = next.findIndex(ele => ele.active === true)

        //   if ( prev_active_index !== -1 ) {
        //     next[prev_active_index].active = false
        //   }

        //   next[index].active = true
          
        //   return next
        // })
      }
    })()
    
  }

  const clicked_delete_quiz = (e:any, user_name:string) => {
    // console.log(user_name)
    e.stopPropagation()
    let active_product_index = products.findIndex((ele:any) => ele.active === true)
    setSelectedProductIndex(active_product_index)
    setDelUserName(user_name)
    setDeleteUserOfQuizDialogOpen(true)
  }

  const clicked_generate_example = (e:any, row:any) => {
    // console.log(user_name)
    e.stopPropagation()
    console.log('generate the example ... ')
    console.log(row, question_arr)
    let active_product_index = products.findIndex((ele:any) => ele.active === true)
    setSelectedProductIndex(active_product_index)
    setSelectedRow(row)
    setGenerateExampleDialogOpen(true)
    
    // let active_product_index = products.findIndex((ele:any) => ele.active === true)
    // setSelectedProductIndex(active_product_index)
    // setDelUserName(user_name)
    // setDeleteUserOfQuizDialogOpen(true)
  }

  return (
    <>
    <Box sx={{ display: 'flex' }}>
        {/* <AdminHeader /> */}
          <Test />
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              height: '100vh',
              overflow: 'auto',
            }}
          >
            <Toolbar sx={{minHeight: '48px!important'}}/>
            <Container sx={{ mt: 0, mb: 0, pl: '0!important', pr: '0!important', maxWidth: '100%!important' }}>
              <CustomGrid container spacing={0} className={"pt-0"}>
                <CustomGrid item xs={12}  className={"pt-0"}>
                  <Box sx={{ typography: 'body1' }}>
                    <div>
                      {
                        isFirstLoading === true ? 
                        <Backdrop
                          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                          open={isFirstLoading}
                          // onClick={handleClose}
                        >
                          <CircularProgress color="inherit"/>
                          <p>&nbsp;&nbsp; Loading ...</p>
                        </Backdrop> : <></>
                      }
                      {
                        isLoading === true ? 
                        <Backdrop
                          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                          open={isLoading}
                          // onClick={handleClose}
                        >
                          <CircularProgress color="inherit"/>
                          <p>&nbsp;&nbsp; Loading ...</p>
                        </Backdrop> : <></>
                      }
                      {/* <AdminHeader /> */}
                      {/* <HeaderContainer>
                        <CustomGrid container spacing={0} sx={{paddingTop: '10px'}}>
                          <CustomGrid item xs={2} className="grid-header-left header_logo" onClick={goStartPage} >
                            <img src={require('assets/logo_avatar3.png').default} style={{width: '52px!important'}} alt="" />
                            &nbsp;<span><BoldSpan>T</BoldSpan>op<BoldSpan>B</BoldSpan>est<BoldSpan>S</BoldSpan>ellers</span>
                          </CustomGrid>
                          <CustomGrid item xs={10} className="grid-flex-item-end grid-header-right" >
                            <CustomDiv className={"nav_link"}><span className={"nav_txt"}>Data</span></CustomDiv>
                            <CustomDiv className={"nav_link"}><span className={"nav_txt"}>Products</span></CustomDiv>
                            <CustomDiv className={"nav_link"}><span className={"nav_txt"}>Quiz</span></CustomDiv>
                            <CustomDiv className={"nav_link"}><span className={"nav_txt"}>Quiz Analyze</span></CustomDiv>
                            <CustomDiv className={"nav_link"}><span className={"nav_txt"}>Quiz Analyze Users</span></CustomDiv>
                          </CustomGrid>
                        </CustomGrid>
                        <Divider />
                      </HeaderContainer> */}
                      <CustomGrid container spacing={0}>
                        <CustomGrid item xs={12} className={'grid_header'}>
                            <MobileHeader 
                                products={products} 
                                clicked_product = {clicked_product}
                            />
                        </CustomGrid>
                        <CustomGrid item xs={12} md={2}  className={"grid_list"}>
                            <CustomDiv className={"list_div"}>
                              {
                                products.length > 0 && products.map((product:any, index:number) => (
                                    <CustomDiv key={index} className={"btn_div"}>
                                        <CustomDiv className={product.active === true ? "active_list_item btn_style" : "btn_style"} onClick={() => clicked_product(product.active, index, product.product_name, product._id)}>
                                            <CustomDiv className={"txt_div"}>
                                                {product.product_name}
                                            </CustomDiv>                                        
                                        </CustomDiv>
                                    </CustomDiv>
                                ))
                              } 
                            </CustomDiv>
                        </CustomGrid>
                        <CustomGrid item xs={12} md={10}>
                            {/* <CustomDiv className={"add_item_div"}>
                                
                            </CustomDiv> */}
                            <CustomDiv className={"content_div"}>  
                              <TableContainer component={Paper} style={{overflowX: 'initial', width: 'fit-content', minWidth: '100%'}}>
                                <Table aria-label="collapsible table">
                                  <TableHead>
                                    <TableRow>
                                      {
                                        question_arr.length > 0 ?
                                        <>
                                          <CustomTableCell className={'t_head'}/>
                                          <CustomTableCell className={'t_head'} component="th" scope="row">User Name</CustomTableCell>
                                        </> 
                                        : <></>
                                      }
                                      
                                      {
                                        question_arr.length > 0 && question_arr.map((ele:any, index:number) => {
                                          return <CustomTableCell key={index} align="center" className={index === 0 ? 't_head question_t_head_first_screener' : 't_head question_t_head'}>{ele}</CustomTableCell>
                                        })
                                      }
                                      {/* <TableCell align="right">Calories</TableCell>
                                      <TableCell align="right">Fat&nbsp;(g)</TableCell>
                                      <TableCell align="right">Carbs&nbsp;(g)</TableCell>
                                      <TableCell align="right">Protein&nbsp;(g)</TableCell> */}
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {user_and_choice_arr.map((ele:any, index:number) => (
                                      <Row key={index} row={ele} question_arr_length={question_arr.length} clicked_delete_quiz={clicked_delete_quiz} clicked_generate_example={clicked_generate_example} />
                                    ))}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </CustomDiv>
                        </CustomGrid>
                    </CustomGrid>
                    </div>  
                  </Box>
                  </CustomGrid>
              </CustomGrid>
            </Container>
          </Box>
        </Box>
     
      <DeleteUserOfQuizDialog 
          open={deleteUserOfQuizDialogOpen} 
          setOpen={setDeleteUserOfQuizDialogOpen} 
          selected_product_index={selected_product_index} 
          del_user_name={del_user_name} 
          products={products} 
          setProducts={setProducts} 
      />     

      <GenerateExampleDialog 
          open={generateExampleDialogOpen} 
          setOpen={setGenerateExampleDialogOpen} 
          selected_product_index={selected_product_index} 
          products={products} 
          row={selected_row} 
          question_arr={question_arr} 
          setProducts={setProducts} 
      />   
    </>                                                               
  );
};

const HeadTableCell = styled1(TableCell)`
  font-weight: 700!important;
`

const CustomTableRow = styled1(TableRow)`
  &.user_info_row {
    background-color: #e2e9f7b8!important;
  }
`

const CustomTableCell = styled1(TableCell)`
  &.t_head {
    font-weight: 600!important;
  }
  &.question_t_head {
    color: #048ef3!important;
  }
  &.question_t_head_first_screener {
    color: #048aaa!important;
  }
`

const CustomDiv = styled1.div`
  &.input_div {
    display: flex;
    justify-content: center;
    padding-bottom: 10px!important;
  }
  &.answer_div {
    display: flex;
    justify-content: space-between;
  }
  &.txt_div {
    flex: 1 1 auto!important;
  }
  &.list_div {
    height: 85vh;
    overflow: auto;
    border-right: 1px solid darkgray!important;
    padding-right: 5px!important;
    padding-top: 5px!important;
  }
  &.content_div {
    height: 85vh;
    overflow: auto;
    padding-left: 5px!important;
    padding-right: 5px!important;
    padding-top: 5px!important;
  }

  &.btn_style {
      display: flex;
      padding: 8px 16px;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
  }

  &.btn_div {
    border-bottom: 1px solid #c1bbbb22;
    margin: 2px 0px;
    transition: all 350ms ease-in-out;
  }
  
  &.btn_div:hover {
    background-color: #00000022!important;
  }

  &.active_list_item {
      background-color: #1e947e;
      color: white;
      transition: all 300ms ease-in-out;
  }
  &.delete_icon: hover {
    cursor: pointer;
    color: #ff3100cf!important;
  }
  &.min-width-0 {
    min-width: 0px!important;
    margin: 0 1px!important;
  }

  &.nav_link {
    // color: #fff;
    display: flex;
    justify-content: center;
    width: 200px;
    transition: 0.7s;
    text-decoration: none;
    padding: 0px 15px;
    border: 1px solid transparent;
    border-radius: 10px;
    margin: 0 10px;
    cursor: pointer;
  }

  &.nav_link:hover {
    // transform: scale(1.1);
    // font-size: 22px;
    border-color: #5989f161;
    box-shadow: 0 2px 5px 0 rgb(15 122 233 / 25%), 0 3px 10px 5px rgb(13 115 245 / 12%) !important;
    .nav_txt {
      color: #2563eb;
      letter-spacing: 1px;
    }
  }

  &.nav_link .nav_txt{
    font-size: 20px;
    font-weight: 500;
    transition: 0.5s;
  }

  // &.nav_link .nav_txt:hover{
  //   color: #2563eb;
  // }

  
`

const CustomGrid = styled1(Grid)`
  background-color: white!important;
  padding: 2px;
  margin: 0px!important;

  &.grid_header {
    @media (min-width: ${screenSizes.mediaM}px) {
        display: none!important;
    }
  }
  &.grid_list {
    @media (max-width: ${screenSizes.mediaM}px) {
        display: none!important;
    }
  }
  .header_logo{
    display: flex;
    align-items: center;
    font-size: 18px;
    cursor: pointer;
  }

  img {
    width: 25px;
    height: 25px;
  }

  .grid-flex-item-end {
    display: flex;
    justify-content: end;
  }

  .grid-flex-item-start {
    display: flex;
    justify-content: start;
  }

  .grid-header-left {
   
  }

  .grid-header-right {
    @media (min-width: ${screenSizes.mediaXLL}px) {
      padding-right: 105px;
    }
    @media (min-width: ${screenSizes.mediaXXL}px) {
      padding-right: 155px;
    }
    @media (min-width: ${screenSizes.mediaXXXX}px) {
      padding-right: 185px;
    }
  }

  .custom-paper {
    @media (min-width: ${screenSizes.mediaM}px) {
      width: 300px;
    }
    @media (min-width: ${screenSizes.mediaXL}px) {
      width: 355px;
    }
  }

  .pt-0 {
    padding-top: 0px!important;
  }

  .grid_main{
    display: flex;
    justify-content: start;
  }

  .main_grid {
    padding: 0px 50px 0px!important;
    display: flex;
    justify-content: center;
  }
  .circle_loading_main_grid {
    padding: 50px 50px 0px!important;
    display: flex;
    justify-content: center;
  }

  .circle_loading_text_div {
    display: flex!important;
    justify-content: center!important;
    font-size: 22px!important;
    font-weight: 500!important;
    padding: 20px 0!important;
    text-align:center!important;
  }

  .progress_div {
    display: flex;
    justify-content: center;
    // flex-direction: column;
  }

  .analyze_result_div {
    width: 100%;
  }
`

const HeaderContainer = styled1.div`
  @media (max-width: ${screenSizes.mediaM}px) {
    display: none;
  }
`

const BoldSpan = styled1.span`
  font-weight: 700;
`

export default Admin_Quiz_Analyze_Users