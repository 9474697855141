import axios from 'axios'

// axios.defaults.baseURL = 'http://localhost:5001/api/v1/'

// axios.defaults.baseURL = 'http://localhost:5000/api/subgraph'
// axios.defaults.baseURL = 'http://ec2-3-94-243-115.compute-1.amazonaws.com:3000/api/v1/';
// axios.defaults.baseURL = 'https://topbestsellers.org:3000/api/v1/';

axios.defaults.baseURL = 'https://productwiz.co/api/v1/'

// axios.defaults.baseURL = 'https://topbestsellersbackend.herokuapp.com/api/v1/'

/////////////////////////////////////////////////////////////// User Api /////////////////////////////////////////////////////////////

export async function login(userData: any) {
  return await axios
    .post('/admins/login', userData)
    .then((res) => {
      return {
        status: 'success',
        result: res.data,
      }
    })
    .catch((err) => {
      return {
        status: 'fail',
        result: err.response.data,
      }
    })
}

export async function register(userData: any) {
  return await axios
    .post('/admins/register', userData)
    .then((res) => {
      return {
        status: 'success',
        result: res.data,
      }
    })
    .catch((err) => {
      return {
        status: 'fail',
        result: err.response.data,
      }
    })
}

export async function get_user_info_by_user_id(user_id: string | null) {
  return await axios
    .post(
      '/admins/get_user_info_by_user_id',
      {
        user_id,
      },
      {
        headers: {
          'Access-Control-Allow-Headers': '*',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': '*',
        },
      }
    )
    .then((res) => {
      // console.log(res)
      return res
    })
}

/////////////////////////////////////////////////////////////// Base Api /////////////////////////////////////////////////////////////

export async function getAmazonJPN(category: string) {
  return await axios
    .get('/amazon/jpn', {
      params: {
        category,
      },
    })
    .then((res) => {
      return res.data
    })
}

export async function getAmazonJPN_mongo(product_url: string) {
  let amazon_tracking_id = localStorage.getItem('amazon_tracking_id')
  let _id = localStorage.getItem('_id')
  return await axios
    .get('/amazon/jpn_mongo', {
      params: {
        product_url,
        amazon_tracking_id,
        _id,
      },
    })
    .then((res) => {

      // console.log('res.data')
      // console.log(res.data)
      return res.data
    })
}

export async function checkAndAdd_userAndId() {
  let gclid = localStorage.getItem('gclid')
  let msclkid = localStorage.getItem('msclkid')
  let search = localStorage.getItem('search')
  let amazon_tracking_id = localStorage.getItem('amazon_tracking_id')
  let _id = localStorage.getItem('_id')
  let link = localStorage.getItem('link')
  return await axios
    .post('/amazon/user_check_and_add_with_gclid_amazon_tracking_id', {
      gclid,
      msclkid,
      amazon_tracking_id,
      search,
      _id,
      link,
    })
    .then((res) => {
      localStorage.setItem('gclid', res.data.data.gclid)
      localStorage.setItem('msclkid', res.data.data.msclkid)
      localStorage.setItem('search', res.data.data.search)
      localStorage.setItem('amazon_tracking_id', res.data.data.amazon_tracking_id)
      localStorage.setItem('_id', res.data.data._id)
      return res
    })
}

export async function recordUserAndIds(
  gclid: string | null,
  msclkid: string | null,
  amazon_tracking_id: string | null,
  visited_url: string,
  search: string | null,
  _id: string | null,
  levanta_info: any
) {
  return await axios
    .post('/amazon/record_user_and_ids', {
      gclid,
      msclkid,
      amazon_tracking_id,
      visited_url,
      search,
      _id,
      levanta_info,
    })
    .then((res) => {
      return res.data.status
    })
}

export async function recordUserAndIdsToRecordTableWhenVisitProductPage(
  gclid: string | null,
  msclkid: string | null,
  amazon_tracking_id: string | null,
  product_url: string,
  search: string | null,
  _id: string | null,
  levanta_info: any
) {
  return await axios
    .post('/amazon/record_user_and_ids_when_visit_product_page', {
      gclid,
      msclkid,
      amazon_tracking_id,
      product_url,
      search,
      _id,
      levanta_info,
    })
    .then((res) => {
      return res.data.status
    })
}

export async function getRecords(page: number, rows_per_page: number) {
  return await axios
    .post('/amazon/get_records', {
      page,
      rows_per_page,
    })
    .then((res) => {
      // return res.data
      return {
        status: 'success',
        data: res.data,
      }
    })
    .catch((err) => {
      // console.log(err)
      return {
        status: 'error',
        error: err,
      }
    })
}

export async function getUsers(page: number, rows_per_page: number) {
  return await axios
    .post('/amazon/get_users', {
      page,
      rows_per_page,
    })
    .then((res) => {
      // return res.data
      return {
        status: 'success',
        data: res.data,
      }
    })
    .catch((err) => {
      // console.log(err)
      return {
        status: 'error',
        error: err,
      }
    })
}

export async function getAmazonPa50(category: string) {
  return await axios
    .get('/amazon/pa50/search', {
      params: {
        category,
      },
    })
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      // console.log(err)
    })
}

/////////////////////////////////////////////////////////////// Product Api /////////////////////////////////////////////////////////////

export async function getVariation(asin: string) {
  return await axios
    .get('/amazon/get_variations', {
      params: {
        asin,
      },
    })
    .then((res) => {
      // console.log(res)
      return res.data
    })
    .catch((err) => {
      // console.log(err)
      return err
    })
}

///////////////////////////////////////////////////////////// get menu data //////////////////////////////////////////////////////////////////

export async function get_menu_data() {
  return await axios.post('/amazon/test/get_menu_data', {}).then((res) => {
    return res.data
  })
}

///////////////////////////////////////////////////////////// admin api //////////////////////////////////////////////////////////////////////

export async function get_products_with_family_name(list_name: string) {
  return await axios
    .post('/amazon/admin/get_products_with_family_name', {
      family_name: list_name,
    })
    .then((res) => {
      // return res.data
      return {
        status: 'success',
        data: res.data,
      }
    })
    .catch((err) => {
      // console.log(err)
      return {
        status: 'error',
        error: err,
      }
    })
}

export async function add_new_category(family_name: string, category: string) {
  return await axios
    .post('/amazon/admin/add_new_category', {
      family_name: family_name,
      category: category,
    })
    .then((res) => {
      return res.data
    })
}

export async function delete_category_by_id(product_id: string) {
  return await axios
    .post('/amazon/admin/delete_category_by_id', {
      product_id,
    })
    .then((res) => {
      return res.data
    })
}

export async function update_category_by_id(product_id: string, edited_category: string) {
  return await axios
    .post('/amazon/admin/update_category_by_id', {
      product_id,
      edited_category,
    })
    .then((res) => {
      return res.data
    })
}

export async function add_new_item_to_product(product_id: string, item_detail_page_url: string) {
  return await axios
    .post('/amazon/admin/add_new_item_to_product', {
      product_id,
      item_detail_page_url,
    })
    .then((res) => {
      return res.data
    })
}

export async function delete_item_from_product(product_id: string, del_index: number) {
  return await axios
    .post('/amazon/admin/delete_item_from_product', {
      product_id,
      del_index,
    })
    .then((res) => {
      return res.data
    })
}

export async function update_item_title(product_id: string, edit_index: number, new_items: any) {
  return await axios
    .post('/amazon/admin/update_item_title', {
      product_id,
      edit_index,
      new_items,
    })
    .then((res) => {
      return res.data
    })
}

export async function update_item(product_id: string, edit_index: number, new_url: string) {
  return await axios
    .post('/amazon/admin/update_item', {
      product_id,
      edit_index,
      new_url,
    })
    .then((res) => {
      return res.data
    })
}

export async function update_item_position(product_id: string, items: any[], index: number, direction: string) {
  return await axios
    .post('/amazon/admin/update_item_position', {
      product_id,
      items,
      index,
      direction,
    })
    .then((res) => {
      return res.data
    })
}

export async function move_category(product_id: string, list_name: string) {
  return await axios
    .post('/amazon/admin/move_category', {
      product_id,
      list_name,
    })
    .then((res) => {
      return res.data
    })
}

////////////////////////////////////////////////////  quiz  //////////////////////////////////////////////////////////////

export async function add_new_quiz(
  image_url: string,
  product_url: string,
  product_name: string,
  best_url: string,
  model_name: string,
  iframe_url: string,
  description: string,
  num_of_quizs: number
) {
  return await axios
    .post('/quiz/admin/add_new_quiz', {
      image_url,
      product_url,
      product_name,
      best_url,
      model_name,
      iframe_url,
      description,
      num_of_quizs,
    })
    .then((res) => {
      // return res.data
      return {
        status: 'success',
        data: res.data,
      }
    })
    .catch((err) => {
      // console.log(err)
      return {
        status: 'error',
        error: err,
      }
    })
    .catch((e) => {
      // console.log(e)

      return {
        status: 'error'
      }
    })
}

export async function read_quiz_with_quiz_url(quiz_url: string) {
  let amazon_tracking_id = localStorage.getItem('amazon_tracking_id')
  let _id = localStorage.getItem('_id')
  return await axios
    .post('/quiz/read_quiz_with_quiz_url', {
      quiz_url,
      amazon_tracking_id,
      _id,
    })
    .then((res) => {
      return res.data
    })
    .catch((e) => {
      // console.log(e)

      return {
        status: 'error'
      }
    })
}

export async function record_user_initially(quiz_id: string) {
  let user_id = localStorage.getItem('_id')

  return await axios
    .post('/quiz/record_user_initially', {
      quiz_id,
      user_id,
    },
    {
      headers: {
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': '*',
      },
    })
    .then((res) => {
      // console.log(res.data)
      return res.data
    })
    .catch((e) => {
      // console.log(e)

      return {
        status: 'error'
      }
    })
}

export async function record_best_url_visited_user(quiz_id: string, log_file_name:string) {
  let user_id = localStorage.getItem('_id')

  return await axios
    .post('/quiz/record_best_url_visited_user', {
      quiz_id,
      user_id,
      log_file_name,
    })
    .then((res) => {
      // console.log(res.data)
      return res.data
    })
    .catch((e) => {
      // console.log(e)

      return {
        status: 'error'
      }
    })
}

export async function read_all_quiz() {
  return await axios
    .post('/quiz/read_all_quiz', {

    })
    .then((res) => {
      return res.data
    })
    .catch((e) => {
      // console.log(e)

      return {
        status: 'error'
      }
    })
}

export async function read_all_examples() {
  return await axios
    .post('/quiz/read_all_examples', {

    })
    .then((res) => {
      return res.data
    })
    .catch((e) => {
      // console.log(e)

      return {
        status: 'error'
      }
    })
}

export async function read_quiz_by_id(quiz_id: string) {
  return await axios
    .post('/quiz/read_quiz_by_id', {
      quiz_id,
    })
    .then((res) => {
      return res.data
    })
    .catch((e) => {
      // console.log(e)

      return {
        status: 'error'
      }
    })
}

export async function record_each_question_answers(quiz_id: string, activeQuestion: number, answer: string, log_file_name:string) {
  let user_id = localStorage.getItem('_id')

  return await axios
    .post('/quiz/record_each_question_answers', {
      quiz_id,
      user_id,
      question_index: activeQuestion,
      choice: answer,
      log_file_name,
    })
    .then((res) => {
      // console.log(res.data)
      return res.data
    })
    .catch((e) => {
      // console.log(e)

      return {
        status: 'error'
      }
    })
}

export async function generate_result(question_answer_str: string, model_name: string, description: string, quiz_id: string, result: [], log_file_name: string) {
  let user_id = localStorage.getItem('_id')
  // console.log(user_id)
  return await axios
    .post('/quiz/generate_result', {
      question_answer_str,
      model_name,
      description,
      quiz_id,
      result,
      user_id,
      log_file_name,
    })
    .then((res) => {
      // console.log(res.data)
      return res.data
    })
    .catch((e) => {
      // console.log(e)

      return {
        status: 'error'
      }
    })
}

export async function generate_result_for_simulation(question_answer_str: string, model_name: string, description: string, quiz_id: string, result: [], log_file_name: string) {
  let user_id = localStorage.getItem('_id')
  // console.log(user_id)
  return await axios
    .post('/quiz/generate_result_for_simulation', {
      question_answer_str,
      model_name,
      description,
      quiz_id,
      result,
      user_id,
      log_file_name,
    })
    .then((res) => {
      // console.log(res.data)
      return res.data
    })
    .catch((e) => {
      // console.log(e)

      return {
        status: 'error'
      }
    })
}

export async function update_model_and_result_text_of_user_info_on_quiz(quiz_id: string, model_name: string, generated_result_text: string, generated_result_text_gpt_4: string, log_file_name: string) {
  let user_id = localStorage.getItem('_id')
  return await axios
    .post('/quiz/update_model_and_result_text_of_user_info_on_quiz', {
      quiz_id,
      user_id,
      model_name,
      generated_result_text,
      generated_result_text_gpt_4,
      log_file_name,
    })
    .then((res) => {
      return res.data
    })
    .catch((e) => {
      // console.log(e)

      return {
        status: 'error'
      }
    })
}

export async function update_text_link_of_user_info_on_quiz(quiz_id: string, link: string, log_file_name: string) {
  let user_id = localStorage.getItem('_id')

  return await axios
    .post('/quiz/update_text_link_of_user_info_on_quiz', {
      quiz_id,
      user_id,
      link,
      log_file_name
    })
    .then((res) => {
      return res.data
    })
    .catch((e) => {
      // console.log(e)

      return {
        status: 'error'
      }
    })
}

export async function update_name_email_phone_of_user_info_on_quiz(quiz_id: string, first_name: string, last_name: string, email: string, phone_number: string, log_file_name: string) {
  let user_id = localStorage.getItem('_id')

  return await axios
    .post('/quiz/update_name_email_phone_of_user_info_on_quiz', {
      quiz_id,
      user_id,
      first_name,
      last_name,
      email,
      phone_number,
      log_file_name,
    })
    .then((res) => {
      return res.data
    })
    .catch((e) => {
      // console.log(e)

      return {
        status: 'error'
      }
    })
}

export async function update_image_link_of_user_info_on_quiz(quiz_id: string, link: string, log_file_name: string) {
  let user_id = localStorage.getItem('_id')

  return await axios
    .post('/quiz/update_image_link_of_user_info_on_quiz', {
      quiz_id,
      user_id,
      link,
      log_file_name
    })
    .then((res) => {
      return res.data
    })
    .catch((e) => {
      // console.log(e)

      return {
        status: 'error'
      }
    })
}

export async function save_example_to_logfile(log_file_name: string, new_example: string) {
  // let user_id = localStorage.getItem('_id')

  return await axios
    .post('/quiz/save_example_to_logfile', {
      log_file_name,
      new_example,
    })
    .then((res) => {
      return res.data
    })
    .catch((e) => {
      // console.log(e)

      return {
        status: 'error'
      }
    })
}

export async function update_btn_link_of_user_info_on_quiz(quiz_id: string, link: string, log_file_name: string) {
  let user_id = localStorage.getItem('_id')

  return await axios
    .post('/quiz/update_btn_link_of_user_info_on_quiz', {
      quiz_id,
      user_id,
      link,
      log_file_name
    })
    .then((res) => {
      return res.data
    })
    .catch((e) => {
      // console.log(e)

      return {
        status: 'error'
      }
    })
}

export async function read_response_by_question(question: string) {
  // console.log(question)
  return await axios
    .post('/quiz/read_response_by_question', {
      question,
    })
    .then((res) => {
      return res.data
    })
    .catch((e) => {
      // console.log(e)

      return {
        status: 'error'
      }
    })
}

export async function read_response_list() {
  return await axios
    .post('/quiz/read_response_list', {
      
    })
    .then((res) => {
      return res.data
    })
    .catch((e) => {
      // console.log(e)
      return {
        status: 'error'
      }
    })
}

export async function add_new_model(
  product_id: string,
  image_url: string,
  product_url: string,
  model_name: string,
  iframe_url: string,
  description: string
) {
  return await axios
    .post('/quiz/admin/add_new_model', {
      product_id,
      image_url,
      product_url,
      model_name,
      iframe_url,
      description,
    })
    .then((res) => {
      return res.data
    })
    .catch((e) => {
      return {
        status: 'error',
        e: e,
      }
    })
}

export async function generate_model_via_amazon_with_prouct_url(product_url: string) {
  return await axios
    .post('/quiz/admin/generate_model_via_amazon_with_prouct_url', {
      product_url,
    })
    .then((res) => {
      return res.data
    })
    .catch((e) => {
      return {
        status: 'error',
        e: e,
      }
    })
}

export async function generate_model_via_amazon_with_prouct_url_02(product_id: string, product_urls: []) {
  return await axios
    .post('/quiz/admin/generate_model_via_amazon_with_prouct_url_02', {
      product_id,
      product_urls,
    })
    .then((res) => {
      return res.data
    })
    .catch((e) => {
      return {
        status: 'error',
        e: e,
      }
    })
}

export async function update_quiz_product_name_by_id(product_id: string, edited_product_name: string) {
  return await axios
    .post('/quiz/admin/update_quiz_product_name_by_id', {
      product_id,
      edited_product_name,
    })
    .then((res) => {
      return res.data
    })
    .catch((e) => {
      // console.log(e)
      return {
        status: 'error',
        e: e,
      }
    })
}
export async function update_quiz_best_url_by_id(product_id: string, edited_best_url: string, log_file_name: string) {
  return await axios
    .post('/quiz/admin/update_quiz_best_url_by_id', {
      product_id,
      edited_best_url,
      log_file_name,
    })
    .then((res) => {
      return res.data
    })
    .catch((e) => {
      // console.log(e)
      return {
        status: 'error',
        e: e,
      }
    })
}

export async function update_quiz_model_name_by_id(product_id: string, selected_model_index: number, edited_model_name: string) {
  return await axios
    .post('/quiz/admin/update_quiz_model_name_by_id', {
      product_id,
      edited_model_name,
      selected_model_index,
    })
    .then((res) => {
      return res.data
    })
    .catch((e) => {
      // console.log(e)
      return {
        status: 'error',
        e: e,
      }
    })
}

export async function update_quiz_model_iframe_url_by_id(product_id: string, selected_model_index: number, edited_iframe_url: string) {
  return await axios
    .post('/quiz/admin/update_quiz_model_iframe_url_by_id', {
      product_id,
      edited_iframe_url,
      selected_model_index,
    })
    .then((res) => {
      return res.data
    })
    .catch((e) => {
      // console.log(e)
      return {
        status: 'error',
        e: e,
      }
    })
}

export async function update_quiz_image_url_by_id(product_id: string, selected_model_index: number, edited_image_url: string) {
  return await axios
    .post('/quiz/admin/update_quiz_image_url_by_id', {
      product_id,
      selected_model_index,
      edited_image_url,
    })
    .then((res) => {
      return res.data
    })
    .catch((e) => {
      // console.log(e)
      return {
        status: 'error',
        e: e,
      }
    })
}
export async function update_quiz_background_image_url_by_id(product_id: string, edited_image_url: string) {
  return await axios
    .post('/quiz/admin/update_quiz_background_image_url_by_id', {
      product_id,
      // selected_model_index,
      edited_image_url,
    })
    .then((res) => {
      return res.data
    })
    .catch((e) => {
      // console.log(e)
      return {
        status: 'error',
        e: e,
      }
    })
}

export async function update_buyer_guide_image_url_by_id(product_id: string, edited_image_url: string) {
  return await axios
    .post('/amazon/admin/update_buyer_guide_image_url_by_id', {
      product_id,
      // selected_model_index,
      edited_image_url,
    })
    .then((res) => {
      return res.data
    })
    .catch((e) => {
      // console.log(e)
      return {
        status: 'error',
        e: e,
      }
    })
}

// export async function generate_img_via_openai_with_only_prompt(product_id: string, edit_quiz_index: number, prompt: string) {
export async function generate_img_via_openai_with_only_prompt(prompt: string) {
  return await axios
    .post('/quiz/admin/generate_img_via_openai_with_only_prompt', {
      // product_id,
      // edit_quiz_index,
      prompt
    })
    .then((res) => {
      return res.data
    })
    .catch((e) => {
      // console.log(e)
      return {
        status: 'error',
        e: e,
      }
    })
}
export async function generate_img_via_openai_for_each_quiz(product_id: string, edit_quiz_index: number) {
  return await axios
    .post('/quiz/admin/generate_img_via_openai_for_each_quiz', {
      product_id,
      edit_quiz_index,
    })
    .then((res) => {
      return res.data
    })
    .catch((e) => {
      // console.log(e)
      return {
        status: 'error',
        e: e,
      }
    })
}

export async function save_image_on_server_and_update_each_quiz_image_url_by_id(product_id: string, edit_quiz_index: number, edited_image_url: string, prompt:string) {
  return await axios
    .post('/quiz/admin/save_image_on_server_and_update_each_quiz_image_url_by_id', {
      product_id,
      edit_quiz_index,
      edited_image_url,
      prompt
    })
    .then((res) => {
      return res.data
    })
    .catch((e) => {
      // console.log(e)
      return {
        status: 'error',
        e: e,
      }
    })
}
export async function save_image_on_server_and_s3_and_update_each_quiz_image_url_by_id(product_id: string, edit_quiz_index: number, edited_image_url: string, prompt: string, log_file_name: string) {
  return await axios
    .post('/quiz/admin/save_image_on_server_and_s3_and_update_each_quiz_image_url_by_id', {
      product_id,
      edit_quiz_index,
      edited_image_url,
      prompt,
      log_file_name,
    })
    .then((res) => {
      return res.data
    })
    .catch((e) => {
      // console.log(e)
      return {
        status: 'error',
        e: e,
      }
    })
}
export async function update_each_quiz_image_url_by_id(product_id: string, edit_quiz_index: number, edited_image_url: string, log_file_name: string) {
  return await axios
    .post('/quiz/admin/update_each_quiz_image_url_by_id', {
      product_id,
      edit_quiz_index,
      edited_image_url,
      log_file_name,
    })
    .then((res) => {
      return res.data
    })
    .catch((e) => {
      // console.log(e)
      return {
        status: 'error',
        e: e,
      }
    })
}

export async function update_quiz_product_url_by_id(product_id: string, selected_model_index: number, edited_product_url: string) {
  return await axios
    .post('/quiz/admin/update_quiz_product_url_by_id', {
      product_id,
      edited_product_url,
      selected_model_index,
    })
    .then((res) => {
      return res.data
    })
    .catch((e) => {
      // console.log(e)
      return {
        status: 'error',
        e: e,
      }
    })
}

export async function update_quiz_description_by_id(product_id: string, selected_model_index: number, edited_description: string) {
  return await axios
    .post('/quiz/admin/update_quiz_description_by_id', {
      product_id,
      selected_model_index,
      edited_description,
    })
    .then((res) => {
      return res.data
    })
    .catch((e) => {
      // console.log(e)
      return {
        status: 'error',
        e: e,
      }
    })
}

export async function update_quiz_selected_model_index_by_id(product_id: string, selected_model_index: number) {
  return await axios
    .post('/quiz/admin/update_quiz_selected_model_index_by_id', {
      product_id,
      selected_model_index,
    })
    .then((res) => {
      return res.data
    })
    .catch((e) => {
      // console.log(e)
      return {
        status: 'error',
        e: e,
      }
    })
}

export async function update_quiz_result_prompt_by_id(product_id: string, edited_result_prompt: string) {
  return await axios
    .post('/quiz/admin/update_quiz_result_prompt_by_id', {
      product_id,
      edited_result_prompt,
    })
    .then((res) => {
      return res.data
    })
    .catch((e) => {
      // console.log(e)
      return {
        status: 'error',
        e: e,
      }
    })
}

export async function update_quiz_gpt_version_by_id(product_id: string, edited_gpt_version: number) {
  return await axios
    .post('/quiz/admin/update_quiz_gpt_version_by_id', {
      product_id,
      edited_gpt_version,
    })
    .then((res) => {
      return res.data
    })
    .catch((e) => {
      // console.log(e)
      return {
        status: 'error',
        e: e,
      }
    })
}

// export async function update_quiz_quizs_by_id(product_id: string, edited_quizs: any) {
//   // console.log(edited_quizs)
//   let edited_quizs_json_str = JSON.stringify(edited_quizs)
//   console.log(edited_quizs_json_str)

//   return await axios
//     .post('/quiz/admin/update_quiz_quizs_by_id', {
//       product_id,
//       quizs_str: edited_quizs_json_str,
//     })
//     .then((res) => {
//       return res.data
//     })
//     .catch((e) => {
//       // console.log(e)
//       return {
//         status: 'error',
//         e: e,
//       }
//     })
// }

export async function update_quiz_quizs_by_id(product_id: string, edited_quizs: any, log_file_name: string) {
  // console.log(edited_quizs)
  let edited_quizs_json_str = JSON.stringify(edited_quizs)
  // console.log(edited_quizs_json_str)

  const formData = new FormData()
  formData.append('product_id', product_id)
  formData.append('large_str', edited_quizs_json_str)
  formData.append('log_file_name', log_file_name)

  return await axios
    .post('/quiz/admin/update_quiz_quizs_by_id', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then((res) => {
      return res.data
    })
    .catch((e) => {
      // console.log(e)
      return {
        status: 'error',
        e: e,
      }
    })
}

export async function regenerate_question(product_id: string, product_name: string, num_of_quizs: number, log_file_name: string) {
  return await axios
    .post('/quiz/admin/regenerate_question', {
      product_id,
      product_name,
      num_of_quizs,
      log_file_name
    })
    .then((res) => {
      return res.data
    })
    .catch((e) => {
      // console.log(e)
      return {
        status: 'error',
        e: e,
      }
    })
}

export async function delete_quiz_by_id(quiz_id: string, log_file_name: string) {
  return await axios
    .post('/quiz/admin/delete_quiz_by_id', {
      quiz_id,
      log_file_name,
    })
    .then((res) => {
      return res.data
    })
    .catch((e) => {
      // console.log(e)
      return {
        status: 'error',
        e: e,
      }
    })
}

// export async function delete_user_of_quiz(quiz_id: string, temp_quiz: any, temp_users: any) {
//   return await axios
//     .post('/quiz/admin/delete_user_of_quiz', {
//       quiz_id,
//       temp_quiz,
//       temp_users,
//     })
//     .then((res) => {
//       return res.data
//     })
//     .catch((e) => {
//       // console.log(e)
//       return {
//         status: 'error',
//         e: e,
//       }
//     })
// }

export async function delete_user_of_quiz(quiz_id: string, temp_quiz: any, temp_users: any) {
  let temp_quiz_str = JSON.stringify(temp_quiz)
  let temp_users_str = JSON.stringify(temp_users)
  
  const formData = new FormData()
  formData.append('quiz_id', quiz_id)
  formData.append('temp_quiz_str', temp_quiz_str)
  formData.append('temp_users_str', temp_users_str)

  return await axios
    .post('/quiz/admin/delete_user_of_quiz', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then((res) => {
      return res.data
    })
    .catch((e) => {
      // console.log(e)
      return {
        status: 'error',
        e: e,
      }
    })
}

export async function update_example_with_index(log_file_name: string, temp_example_array: any) {
  let temp_example_array_str = JSON.stringify(temp_example_array)
  
  const formData = new FormData()
  formData.append('log_file_name', log_file_name)
  formData.append('temp_example_array_str', temp_example_array_str)

  return await axios
    .post('/quiz/admin/update_example_with_index', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then((res) => {
      return res.data
    })
    .catch((e) => {
      // console.log(e)
      return {
        status: 'error',
        e: e,
      }
    })
}

export async function uploadImage(formData: FormData) {
  // console.log(formData)
  return await axios
    // .post('/quiz/admin/upload', formData, {
    .post('/quiz/admin/upload_s3', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((res) => {
      // console.log(res.data)
      return res.data
    })
    .catch((e) => {
      // console.log(e)
      return {
        status: 'error',
        e: e,
      }
    })
}
